
import { userActions } from '../features/userSlice';
import { commonActions, stopLoading } from '../features/commonSlice';
import { plpActions } from '../features/plpSlice';
import { pdpActions } from '../features/pdpSlice';
import { cartActions } from '../features/cartSlice';
import { checkoutActions } from '../features/checkoutSlice';
import { orderActions } from '../features/orderSlice';

const rootAction = {
    userAction: {
        ...userActions
    },
    commonAction: {
        ...commonActions,
        stopLoading
    },
    plpActions: {
        ...plpActions
    },
    pdpActions: {
        ...pdpActions
    },
    cartActions: {
        ...cartActions
    },
    checkoutActions: {
        ...checkoutActions
    },
    orderActions: {
        ...orderActions
    }
}

export default rootAction