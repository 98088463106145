import { useEffect, useState } from 'react';
import ContentPage from '../../components/layout/ContentPage';
import { useDispatch } from 'react-redux';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import Seo from '../../components/shared/Seo';
import LoadingScreen from '../../components/shared/LoadingScreen';


const Returns = () => {
  const [ReturnsDynamicContent, setReturnsDynamicContent] = useState('');
  const dispatch = useDispatch();
  const [isContentLoading,setIsContentLoading] = useState(false);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {

      setIsContentLoading(true);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }

      const contentParam = {
        requestParameters: {
          ContentID: "returnpolicy",
        },
      };

      const contentResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CONTENT_BY_ID'], null, contentParam, headers, "POST", true);
      if (contentResponse != null && contentResponse.data != null) {
        let contentDetail = JSON.parse(contentResponse.data.data);
        await setReturnsDynamicContent(contentDetail[0].ContentValue);
      }

      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Index_Contact_Us"], null);
      if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
        await replaceLoclizationLabel(arryRespLocalization);
      }

      setIsContentLoading(false);

    }
    
    // call the function
    dataOperationInUseEffect().catch(console.error);

    
    
  }, [])

  return (
    <ContentPage title={"Returns"} contentId={"returns"} contentFull={false}>
      <Seo title="Returns" description="Returns" keywords="Returns" />
      <LoadingScreen loading={isContentLoading} />
      <div className='clearfix' dangerouslySetInnerHTML={{ __html: ReturnsDynamicContent }}></div>

    </ContentPage>
  );
}

export default Returns;
