import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Seo from '../../components/shared/Seo';

export const useScript = (url, name) => {

  const [lib, setLib] = useState({})

  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.onload = () => setLib({ [name]: window[name] })

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])

  return lib

}


const ThreeDView = () => {
   const [siteTitle, setSiteTitle] = useState(useSelector(state => state.commonReducer.siteName));

  //  const { ThreeMin } = useScript('3dscripts/three.min.js', 'ThreeMin');
  //  const { LoaderSupport } = useScript('3dscripts/LoaderSupport.js', 'LoaderSupport');
  //  const { OrbitControls } = useScript('3dscripts/OrbitControls.js', 'OrbitControls');
  //  const { OBJLoader2 } = useScript('3dscripts/OBJLoader2.js', 'OBJLoader2');
  //  const { Detector } = useScript('3dscripts/Detector.js', 'Detector');
  const { MainCode } = useScript('3dscripts/main_s.js', 'MainCode');

  return (
    <>
      <Seo
        title={`${siteTitle} - 3D View Pre Alpha`}
        description={`${siteTitle} - 3D Embroidery Preview`}
        keywords={`${siteTitle} - 3D Embroidery Preview`}
      />
      
        <div className="col-md-12 col-md-push-12">
          <div  id="threedcontainer"></div>
        </div>
        
        <div id="svgContainer"style={{display:'none'}}></div>
        <div id="svgPathContainer"style={{display:'none'}}></div>
        <div id="svgTextContainer"style={{display:'none'}}></div>      
      
    </>
  );

}

export default ThreeDView;
