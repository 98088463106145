import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useGetContentAssetQuery } from "../../../services/content";

const ShippingInfoModal = (props) => {
    const {
        open,
        toggle,
        className
    } = props;
    const closeBtn = <button className="close" onClick={toggle}><span className="icon icon-close"></span></button>;
    const {data: shippingMethodMessageLearnMore} = useGetContentAssetQuery('shipping-method-message-learn-more');

    return (
        <Modal isOpen={open} toggle={toggle} className={className} centered={true}>
        <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>
        <ModalBody>
            <div className="clearfix" dangerouslySetInnerHTML={{ __html: shippingMethodMessageLearnMore?.ContentValue }}></div>
        </ModalBody>
        </Modal>
    )
}

export default ShippingInfoModal;
