import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";

const SitePagination = ({ currentPage, totalRecords, pageSize, setCurrentPage, handleByThisComponent = true }) => {

    const navigate = useNavigate();
    const params = useParams();

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        if (handleByThisComponent) {
            navigate('/all-products/' + params.parent_category_id + '/' + params.category_id + '/' + pageNumber);
            setTimeout(() => {
                document.getElementById('products-landing-page').scrollIntoView({ behavior: 'smooth' });
            }, 0);
        }
    }

    const iconPrev = <span className="icon icon-arrowLeft"></span>;
    const iconNext = <span className="icon icon-arrowRight"></span>;
    const iconFirst = <span className="icon icon-arrowFirst"></span>;
    const iconLast = <span className="icon icon-arrowLast"></span>;

    return (
        <div className="clearfix mt-6">
            <Pagination
                activePage={currentPage}
                itemsCountPerPage={pageSize}
                totalItemsCount={totalRecords}
                pageRangeDisplayed={5}
                onChange={handlePageChange.bind(this)}
                prevPageText={iconPrev}
                nextPageText={iconNext}
                firstPageText={iconFirst}
                lastPageText={iconLast}
                activeClass={"active"}
                activeLinkClass={"active"}
                itemClass={"page-item"}
                innerClass={"pagination pagination-lg"}
                linkClass={"page-link"}
            />
        </div>
    );
}

export default SitePagination;
