import React, { useEffect,useContext } from "react";
import { Tooltip } from "reactstrap";
import rootAction from "../../../stateManagment/actions/rootAction";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../helpers/ConversionHelper";
import { ToggleContext } from "../../../ToggleProvider";

const ColorSwatch = ({ color, IsEdit, productAttributes, hasProductSizes, disabled }) => {
    const dispatch = useAppDispatch();
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);    
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const { selectedColorId, selectedSizeId } = useSelector((state) => state.pdpReducer);

    const noOp = () => {

    }
    
    const adjustPriceHelper = (productAttributes, selectedSizeId, colorId, currentPrice) => {
        const attributesForActivity = productAttributes?.find((productAttribute) =>
            productAttribute.productAttributeId === 2 &&
            (productAttribute.colorId ?? "") === colorId &&
            productAttribute.primaryKeyValue?.toLocaleLowerCase() === selectedSizeId?.toLocaleLowerCase());
        return attributesForActivity?.priceAdjustment ?? currentPrice;
    }

    const handleColorClick = async (color) => {
        if (!IsEdit) {
            dispatch(rootAction.pdpActions.setActiveColor({
                ...color,
                noSize: !hasProductSizes
            }));
            dispatch(rootAction.pdpActions.setActiveHexCode(color.hexCode));
            if(!hasProductSizes || selectedSizeId) {
                dispatch(rootAction.pdpActions.setCurrentPrice(adjustPriceHelper(productAttributes, selectedSizeId, color.id ?? "", color.price)));
            }
        }
    }
    const id = `color-swatch-${color.id}`;

    useEffect(() => {
        return () => {
            setTooltipOpen(false);
        }
    }, [selectedColorId]);

    return (
        <>
            <button
                id={id}
                className={`color-attribute swatch-circle ${color.hexCode.toUpperCase() === '#FFFFFF' ? 'swatch-white' : ''} ${disabled ? 'disabled' : ''} ${selectedColorId === color.id ? 'selected' : ''}`}
                style={color.hexCode.split("_").length > 1 ? { background: 'linear-gradient(to right,' + color.hexCode.split("_")[0] + ' 70%, ' + color.hexCode.split("_")[1] + ' 0)' } : { backgroundColor: color.hexCode }}
                onClick={() => IsEdit ? noOp() : handleColorClick(color)}
            >
            </button>
            <Tooltip placement="top" isOpen={tooltipOpen && selectedColorId !== color.id} target={id} toggle={toggle} modifiers={{ offset: { offset: '0, 5' } }}>
            {
                !isToggled?<> {color.name} from <b>{formatCurrency(color.price)}</b></>:<>{color.name}</>
            }

            </Tooltip>
        </>
    )
}

const ColorSwatches = ({ colors, hasError, IsEdit, productAttributes = [], hasProductSizes }) => {
    colors = colors.map((color) => {
        const price = productAttributes
            .filter((productAttribute) => productAttribute.colorId === color.id)
            //minium price reducer
            .reduce((min, productAttribute) => Math.min(min, productAttribute.priceAdjustment), Number.MAX_SAFE_INTEGER);
            
        return { 
            ...color,
            price: price || 0
        };
    });

    return (
        <div className="py-2" disabled={IsEdit}>
            <div className="color-swatches d-flex" disabled={IsEdit}>
                {colors?.map((color) => (
                    <ColorSwatch key={color.id} color={color} IsEdit={IsEdit} productAttributes={productAttributes} hasProductSizes={hasProductSizes} disabled={!color.isOrderable} />
                ))}
            </div>
            <div className={`text-danger ${hasError ? 'd-block' : 'd-none'}`}>Please make a selection.</div>
        </div>
    )
}

export default ColorSwatches;