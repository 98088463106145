import { useSelector } from "react-redux";
import { useRefreshTokenMutation } from "../../../services/auth";
import rootAction from "../../../stateManagment/actions/rootAction";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEffect, useRef } from "react";
import timeOutIcon from '../../../assets/svg-icons/icon_TimedOut.svg';

export const SessionTimeoutModal = () => {
    const modalRef = useRef(null);
    const dispatch = useAppDispatch();
    const [retokenize] = useRefreshTokenMutation();
    const { sessionExpired, sessionExpiringSoon } = useSelector(state => state.commonReducer);
    const SamlLogoutEndpoint = useSelector(state => state.commonReducer.samlLogoutEndpoint);

    const continueSession = async () => {
        dispatch(rootAction.commonAction.sessionExpiringSoon(false));
        dispatch(rootAction.commonAction.sessionExpired(false));
        await retokenize().unwrap()
            .catch((e) => {
                dispatch(rootAction.userAction.logOutUser());
                if(SamlLogoutEndpoint && SamlLogoutEndpoint.length>5){
                    window.location.href =  SamlLogoutEndpoint;
                }
            });
    }

    const handleBeforeUnload = (e) => {
        dispatch(rootAction.commonAction.isRetokenizing(true));
        e.returnValue = '';
    }

    useEffect(() => {
        if (sessionExpired) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [sessionExpired]);

    return (
        <Modal
            ref={modalRef}
            isOpen={sessionExpired || sessionExpiringSoon}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => { }}
        >
            <ModalHeader className="d-block text-center px-7 pt-7" tag='div'>
                <img className="mb-4" src={timeOutIcon} alt="Uh oh..."></img>
                <h4>Uh oh...</h4>
            </ModalHeader>
            <ModalBody className="text-center px-7 pb-7 pt-0">
                {sessionExpired ?
                    <p className="m-0">Your session timed out.<br />
                        Please log in again.</p>
                    :
                    <p className="m-0">Your session is about to expire.<br />
                        Do you want to continue?</p>
                }
            </ModalBody>
            <ModalFooter className="px-7 pb-7">
                {sessionExpired ?
                    <Button color="primary" className="w-100" onClick={() => {dispatch(rootAction.userAction.logOutUser());if(SamlLogoutEndpoint && SamlLogoutEndpoint.length>5){window.location.href =  SamlLogoutEndpoint;}}}>
                        Login
                    </Button>
                    :
                    <>
                        <Button color="primary" className="w-100" onClick={continueSession}>
                            Yes
                        </Button>
                        <a href="#" type="button" className="text-uppercase mx-auto" onClick={(e) => { e.preventDefault(); dispatch(rootAction.userAction.logOutUser()); if(SamlLogoutEndpoint && SamlLogoutEndpoint.length>5){window.location.href =  SamlLogoutEndpoint;}}}>
                            No
                        </a>
                    </>
                }
            </ModalFooter>
        </Modal >
    )
}
