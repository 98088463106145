import { useSelector } from "react-redux";
// import { useRefreshTokenMutation } from "../../../services/auth";
// import rootAction from "../../../stateManagment/actions/rootAction";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useContext, useEffect, useRef } from "react";
import stopIcon from '../../../assets/images/perspective-switch-stop.png';
import { ToggleContext,useSwitching } from "../../../ToggleProvider";
import { useSwitchSessionMutation } from "../../../services/auth";
export const PerspectiveSwitchModal = () => {
    const modalRef = useRef(null);
    const dispatch = useAppDispatch();
    const currentCart = useSelector((state) => state.cartReducer);
    const { isToggled, setIsToggled, intentToSwitch,setIntentToSwitch } = useContext(ToggleContext);
    const { showSwitching, hideSwitching } = useSwitching();
    const [switchSession, { isLoading }] = useSwitchSessionMutation();
    const LogoImageFromStorage = useSelector(state => state.commonReducer.websiteLogoInLocalStorage);
    const siteName = useSelector(state => state.commonReducer.siteName);
    // useEffect(() => {
    //     if (sessionExpired) {
    //         window.addEventListener('beforeunload', handleBeforeUnload);
    //     }
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     }
    // }, [sessionExpired]);

    const continueOnCredits = async () => {
        setIntentToSwitch(false);
    }

    const switchPerspective = async() => {
        setIntentToSwitch(false);
        const Email = "NOOP";
        const Password = "NOOP";
        showSwitching();
        setIsToggled(!isToggled);
        // setIsLoading(true);
        switchSession({ Email, Password })
        .unwrap()
        .catch((error) => {
            //setShowError(true);
            //revert incase of error
            setIsToggled(!isToggled);
            window.location.href = '/';
            //window.location.reload();
        })
        .finally(() => {
            //  setIsLoading(false);
            window.location.href = '/';
            //window.location.reload();
            /*hideSwitching();*/
        });
    }

    return (
        <Modal
            className="sizing-modal"
            ref={modalRef}
            isOpen={intentToSwitch && isToggled && currentCart.totalCartItems > 0}
            centered
            toggle={() => { }}
        >
            <ModalHeader>
                <button className="close" onClick={continueOnCredits}>
                    <span className="icon icon-close"></span>
                </button>
            </ModalHeader>
            <ModalBody className="pt-md-0">
                <h6>You have not finished your allotment order and are about to leave the site.</h6>
                <p>Please note, our self-pay portal does not recognize your allotment portal shopping bag. If you leave the allotment portal without completing the checkout process, the items in your shopping bag may be lost.</p>
                <p className="mb-0">Do you want to complete your allotment portal order first?</p>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <>
                    <Button color="primary" className="py-2 order-sm-1" onClick={continueOnCredits}>
                        Complete<br/>
                        allotment order
                    </Button>
                    <Button color="primary" outline className="py-2 order-sm-0" onClick={switchPerspective}>
                        Continue<br/>
                        to self-pay
                    </Button>
                </>
            </ModalFooter>
        </Modal >
    )
}
