import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import { GetDefaultCurrencySymbol, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import { formatCurrency, makePriceRoundToTwoPlaces } from "../../../helpers/ConversionHelper";
import { ToggleContext } from "../../../ToggleProvider";

export const ShippingBagSummary = ({ cart }) => {
    const [isOpenBagSummary, setIsOpenBagSummary] = useState(false);
    const { totalCartItems } = useSelector(state => state.cartReducer);
    const { isToggled, } = useContext(ToggleContext);
    const toggleBagSummary = () => {
        setIsOpenBagSummary(!isOpenBagSummary);
    };
    const toggleIcon = (visible) => {
        return visible ? <span className='icon icon-arrowUp' /> : <span className='icon icon-arrowDown' />;
    };
    const LocalizationLabelsArray = [];
    return (
        <Card color="light" className="card-sidebar products-summary">
            <CardHeader tag="h5" role="button" onClick={toggleBagSummary}>
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Shopping Bag", "lbl_check_shoppingbag")
                    :
                    "Shopping Bag"
                }
                &nbsp;({totalCartItems})
                {toggleIcon(isOpenBagSummary)}
            </CardHeader>
            <Collapse isOpen={isOpenBagSummary}>
                <CardBody>
                    <ul className="list-group list-group-flush">
                        {cart?.products?.map((data, idx) => (
                            <li className="list-group-item" key={idx}>
                                <div className='product-line-item'>
                                    <div className='product-image'>
                                        <img src={data.images[0]?.url.replace('?sw=100&sh=150&sm=fit', '?sw=130&sh=194&sm=fit')} alt={data.productName} className='img-fluid' />
                                    </div>
                                    <div className='product-info'>
                                        <div className='line-item-title'>
                                            <p>
                                                <strong>{data.productName}</strong>
                                            </p>
                                            <p>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Style", "lbl_check_style")
                                                    :
                                                    "Style"
                                                }
                                                &nbsp;#: {data.productId}
                                            </p>
                                        </div>

                                        <div className="line-item-summary">

                                            {data.colorName && <div className={isToggled ? 'attribute-item justify-content-between' : 'attribute-item '}>
                                                <span className='label'>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Color", "lbl_check_color")
                                                        :
                                                        "Color"
                                                    }
                                                    :
                                                </span>
                                                <span>{data.ColorLongName}</span>
                                            </div>}

                                            {data.sizeShortName &&
                                                <div className={isToggled ? 'attribute-item justify-content-between' : 'attribute-item '}>
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Size", "lbl_check_size")
                                                            :
                                                            "Size"
                                                        }
                                                        :
                                                    </span>
                                                    <span>{data.sizeShortName.replace('_', '/')}</span>
                                                </div>}
                                                {isToggled &&
                                                    <div className="attribute-item justify-content-between">
                                                        <span className='label'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_qty")
                                                                :
                                                                "Qty"
                                                            }
                                                            :
                                                        </span>
                                                        <span>
                                                            {data.quantity}
                                                        </span>
                                                    </div>
                                                }
                                            {data.SelectedEmployee ?
                                                <div className="attribute-item">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Employee", "lbl_check_employee")
                                                            :
                                                            "Employee"
                                                        }
                                                    </span>
                                                    <span>{data.SelectedEmployee.replace(',', ' ')}</span>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>

                                        <div className="line-item-summary">
                                            {!isToggled &&
                                            <div className="summary-item">
                                                <span className='label'>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_price")
                                                        :
                                                        "Price"
                                                    }
                                                </span>
                                                <span>
                                                    {formatCurrency((data.discountedPrice / data.quantity) - data.embroideryPrice)}
                                                </span>
                                            </div>
                                            }

                                            <div className="summary-item">
                                                <span className='label'>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_qty")
                                                        :
                                                        "Qty"
                                                    }
                                                </span>
                                                <span>
                                                    {data.quantity}
                                                </span>
                                            </div>
                                            {!isToggled &&
                                            <div className="summary-item">
                                                <span className='label'>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_total")
                                                        :
                                                        "Total"
                                                    }
                                                </span>
                                                <span>
                                                    {formatCurrency(data.productPrice + (data.embroideryPrice * data.quantity))}
                                                </span>
                                            </div>
                                            }
                                            {data.embroideryPrice > 0 ?
                                                <>
                                                    <hr></hr>
                                                    <div className="summary-item">
                                                        <span className='label'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Embroidery", "lbl_check_embroidery")
                                                                :
                                                                "Embroidery"
                                                            }
                                                        </span>
                                                        {!isToggled &&
                                                        <span>
                                                            {formatCurrency(data.embroideryPrice)}
                                                        </span>
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </CardBody>
            </Collapse>
        </Card>
    )
}