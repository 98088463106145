import { useEffect, useState } from 'react';
import { FormGroup, FormFeedback, Label, Input } from 'reactstrap'
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import { showErrorMsg, validateAnyFormField } from '../../../helpers/ValidationHelper';
import { useSelector, useDispatch } from 'react-redux';
import rootAction from '../../../stateManagment/actions/rootAction';
import { GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import ContentPage from '../../components/layout/ContentPage';
import Seo from '../../components/shared/Seo';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { usePostContactUsMutation } from '../../../services/site';
import { useGetContentAssetQuery } from '../../../services/content';
//import mailSuccessImg from '../../..resources/themeContent/svg-icons/mail-success.svg';

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [contactUs, { isLoading: isContactUsLoading }] = usePostContactUsMutation();
  const [showErrorResponse, setShowErrorResponse] = useState(false);
  const [ErrorResponse, setErrorResponse] = useState('');
  const { isChatEnabled } = useSelector(state => state.commonReducer);
  const [showSuccessResponse, setShowSuccessResponse] = useState(false);

  const { data: dataContactusFormTitle, isFetching: isFetchingContactUs3 } = useGetContentAssetQuery('contactus-form-title');
  const { data: dataContactusFormEmail, isFetching: isFetchingContactUs4 } = useGetContentAssetQuery('contactus-form-email');
  const { data: dataContactusFromChat, isFetching: isFetchingContactUs5 } = useGetContentAssetQuery('contactus-form-chat');
  const { data: dataContactusFormCall, isFetching: isFetchingContactUs6 } = useGetContentAssetQuery('contactus-form-call');

  const handleTabClick = (event, index) => {
    event.stopPropagation();
    setActiveTab(index);
  };
  const [contactUsFields, setContactUsFields] = useState({
    FullName: '',
    Email: '',
    AccountNumber: '',
    OrderNumber: '',
    Subject: '',
    Message: ''
  });

  const [formErrors, setFormErrors] = useState({});

  const handleCloseMessage = () => {
    setShowSuccessResponse(!showSuccessResponse);
  }

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setContactUsFields({ ...contactUsFields, [name]: value });
  };

  const isInvalid = (key) => {
    return formErrors[key] === false;
  }

  const handleContactUsForm = async (event) => {
    event.preventDefault();
    setShowErrorResponse(false);
    let validationArray = {};

    validationArray['fullname'] = validateAnyFormField('Name', contactUsFields.FullName, 'text', null, 200, true);
    validationArray['email'] = validateAnyFormField('Email', contactUsFields.Email, 'email', null, 100, true);
    validationArray['ordernumber'] = validateAnyFormField('Order Number', contactUsFields.OrderNumber, 'number', null, null, false);
    validationArray['accountnumber'] = validateAnyFormField('Account Number', contactUsFields.AccountNumber, 'number', null, null, false);
    validationArray['subject'] = validateAnyFormField('Subject', contactUsFields.Subject, 'text', null, 150, true);
    validationArray['message'] = validateAnyFormField('Message', contactUsFields.Message, 'text', null, 2000, true);

    setFormErrors(validationArray);

    if (Object.keys(validationArray).filter((k) => !validationArray[k]).length === 0) {

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }


      const param = {
        ...contactUsFields
      };

      //--make api call for data operation
      contactUs(param).unwrap().then((response) => {
        if (response != null && response.data != null) {
          setShowErrorResponse(false);
          setShowSuccessResponse(true);
          setContactUsFields({
            FullName: '',
            Email: '',
            AccountNumber: '',
            OrderNumber: '',
            Subject: '',
            Message: ''
          });
        }
      }).catch((error) => {
        setShowErrorResponse(true);
        setShowSuccessResponse(false);
        setErrorResponse(error.data.message);
      });

    }
  }

  return (
    <div className='col-md-9'>
      <LoadingScreen loading={isContactUsLoading || isFetchingContactUs3 || isFetchingContactUs4 || isFetchingContactUs5 || isFetchingContactUs6} />
      <div className="clearfix" dangerouslySetInnerHTML={{ __html: dataContactusFormTitle?.ContentValue }}></div>
      <ul className="nav nav-tabs chat-offline" id="myTab" role="tablist">
        <li onClick={(e) => handleTabClick(e, 0)} className={`nav-item col text-center ${activeTab === 0 ? 'active' : ''}`}>
          <button className="nav-link w-100" id="email-tab" data-toggle="tab" role="tab" aria-controls="email" aria-selected="true">Email</button>
        </li>
        {isChatEnabled ?
          <li onClick={(e) => handleTabClick(e, 1)} className={`nav-item get-in-touch__chat col text-center ${activeTab === 1 ? 'active' : ''}`}>
            <button className="nav-link w-100" id="chat-tab" data-toggle="tab" role="tab" aria-controls="chat" aria-selected="false">
              <span className="btn p-0 offline-chat disabled"><span className="fs-it-btn-vertical-line"></span> Chat</span><span className="btn online-chat" style={{ display: "none" }}><span className="fs-it-btn-vertical-line"></span> Chat</span>
            </button>
          </li>
          :
          <></>
        }
        <li onClick={(e) => handleTabClick(e, 2)} className={`nav-item col text-center ${activeTab === 2 ? 'active' : ''}`}>
          <button className="nav-link call-tab w-100" id="call-tab" data-toggle="tab" role="tab" aria-controls="call" aria-selected="false">Call</button>
        </li>
      </ul>
      <div className="tab-content p-4">
        {activeTab === 0 &&
          <div className="row">
            {showErrorResponse &&
              <div className="col-12" role="alert" aria-hidden="false" data-acsb-hidden="false">
                <div className="alert alert-danger mb-3" aria-hidden="false" data-acsb-hidden="false">{ErrorResponse}</div>
              </div>}

            <div className="col-12">
              <div className="contact-form">
                <div className="clearfix" dangerouslySetInnerHTML={{ __html: dataContactusFormEmail?.ContentValue }}></div>
                <div className='card'>
                  <div className='card-body contact-us-message-form'>
                    <form id="contactForm" onSubmit={handleContactUsForm} className={`${showSuccessResponse ? 'd-none' : 'd-block'}`}>

                      <div className="row">
                        <div className="col-12 col-md-6">
                          <FormGroup className="position-relative form-group required">
                            <Label for="FullName">Full Name</Label>
                            <Input name="FullName" id="FullName" placeholder="Enter your Full Name"
                              invalid={isInvalid('fullname')} value={contactUsFields.FullName} onChange={handleFormChange} />
                            <FormFeedback>Please enter your Name</FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12 col-md-6">
                          <FormGroup className="position-relative form-group required">
                            <Label for="Email">Email</Label>
                            <Input name="Email" id="Email" placeholder="Enter your Email Address"
                              invalid={isInvalid('email')} value={contactUsFields.Email} onChange={handleFormChange} />
                            <FormFeedback>Please enter a valid Email Address</FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12 col-md-6">
                          <FormGroup className="position-relative form-group">
                            <Label for="OrderNumber">Order Number</Label>
                            <Input name="OrderNumber" id="OrderNumber" placeholder="Enter your Order Number"
                              invalid={isInvalid('ordernumber')} value={contactUsFields.OrderNumber} onChange={handleFormChange} />
                            <FormFeedback>Please enter a valid Order Number</FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12 col-md-6">
                          <FormGroup className="position-relative form-group">
                            <Label for="AccountNumber">Account Number</Label>
                            <Input name="AccountNumber" id="AccountNumber" placeholder="Enter your Account Number"
                              invalid={isInvalid('accountnumber')} value={contactUsFields.AccountNumber} onChange={handleFormChange} />
                            <FormFeedback>Please enter a valid Account Number</FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12">
                          <FormGroup className="position-relative form-group required">
                            <Label for="Subject">Subject</Label>
                            <Input name="Subject" id="Subject" type="select" className="form-control"
                              invalid={isInvalid('subject')} value={contactUsFields.Subject} onChange={handleFormChange}>
                              <option value="">Select Reason for Contacting</option>
                              <option value="Order Status">Order Status</option>
                              <option value="Order Change/Cancellation">Order Change/Cancellation</option>
                              <option value="International Order Inquiry">International Order Inquiry</option>
                              <option value="Order Issue">Order Issue</option>
                              <option value="Returns Question">Returns Question</option>
                              <option value="Product/Inventory Inquiry">Product/Inventory Inquiry</option>
                              <option value="Group Sales (> 25 employees)">Group Sales (&gt; 25 employees)</option>
                              <option value="Wholesale Inquiry">Wholesale Inquiry</option>
                              <option value="General Inquiry">General Inquiry</option>
                            </Input>
                            <FormFeedback>Please enter a valid Subject</FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12">
                          <FormGroup className="position-relative form-group required">
                            <Label for="Message">Message <span className="contact-us-comment__title-rule">(2000 CHARACTERS MAX)</span></Label>
                            <Input name="Message" id="Message" placeholder="Enter your Message" type="textarea" rows="4"
                              invalid={isInvalid('message')} value={contactUsFields.Message} onChange={handleFormChange} />
                            <FormFeedback>Please enter your Message</FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12">
                          <button type="submit" className="btn btn-primary" id="lbl_cont_btn_sndmsg">Send Message</button>
                        </div>
                      </div>
                    </form>
                    <div className={`${!showSuccessResponse ? 'd-none' : 'd-flex'} contact-us-signup-message flex-column align-items-center py-9`} aria-hidden="false" data-acsb-hidden="false">
                      <button type="button" className="close contact-us-signup-message-close" onClick={handleCloseMessage}>
                        <span className="icon icon-close"></span>
                      </button>
                      <div className="response-wrapper"><img alt="success" src="https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwdecbf60b/images/staticPages/mail-success.svg" />
                        <br />
                        <h4>Thank you for contacting Uniform Advantage!</h4>
                        <p>Your questions, comments and feedback are greatly appreciated.</p>
                        <br />
                        <p>Please expect a reply within 1 business day.</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        {activeTab === 1 && <div className="col-md-12 mx-auto">
          <div className="get-in-touch__chat">
            <div className="clearfix" dangerouslySetInnerHTML={{ __html: dataContactusFromChat?.ContentValue }}></div>
          </div>
        </div>}
        {activeTab === 2 && <div className="col-md-12 contact-us-text mx-auto">
          <div className="content-asset">
            <div className="contentRow text-center mt-7">
              <div className="clearfix" dangerouslySetInnerHTML={{ __html: dataContactusFormCall?.ContentValue }}></div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

const ContactUs = () => {
  const dispatch = useDispatch();
  const [siteTitle, setSiteTitle] = useState(useSelector(state => state.commonReducer.siteName));
  const { data: dataContactusTopColumns, isFetching: isFetchingContactUs1 } = useGetContentAssetQuery('contactus-top-columns');
  const { data: dataContactusHours, isFetching: isFetchingContactUs2 } = useGetContentAssetQuery('contactus-hours');

  return (
    <>
      <ContentPage onclick="" title={"Contact Us"} contentId={"contact-us-landing-page"} contentFull={false}>
        <Seo title="Contact Us" description="Contact Us" keywords="Contact Us" />
        <LoadingScreen loading={isFetchingContactUs1 || isFetchingContactUs2} />
        <div className="clearfix" dangerouslySetInnerHTML={{ __html: dataContactusTopColumns?.ContentValue }}></div>

        <div className='row'>
          <TabComponent />
          <div className="col-md-3" dangerouslySetInnerHTML={{ __html: dataContactusHours?.ContentValue }}></div>
        </div>
      </ContentPage>
    </>
  );

}

export default ContactUs;
