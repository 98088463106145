import { api } from './api.ts'
import Config from '../helpers/Config'
import { AxiosResponse } from 'axios'
import { unminifyJSON, transformEmbroideryJSON } from '../helpers/CommonHelper.js'

// Define a service using a base URL and expected endpoints
export const orderApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrderDetails: build.query<any, any>({
            query: (orderId: string) => {
                return {
                    url: Config.END_POINT_NAMES.GET_ORDER_DETAIL,
                    method: 'POST',
                    body: {
                        requestParameters: {
                            orderId
                        }
                    },
                }
            },
            transformResponse: (response: AxiosResponse) => {
                try {
                    response.data.OrderDetails.OrderLineItems = response.data.OrderDetails.OrderLineItems?.map((item: any) => {
                        var embConfig = JSON.parse(transformEmbroideryJSON(JSON.stringify(item.VASSalesLineComponents)));
                        if (embConfig) {
                            embConfig = embConfig.map((component: any) => {
                                component.componentAttributes = component.componentAttributes?.map((attr: any) => {
                                    var hex = response.data.HexCodes.find((hexcode: String) => hexcode.startsWith(attr.attributeValue + ","));
                                    if (attr.componentAttribute == 'COLOR' && hex) {
                                        attr.valueHexCode = hex.split(',')[1];
                                    }
                                    return attr;
                                });
                                return component;
                            });
                        }
                        return {
                            ...item,
                            EmbroideryConfiguration: embConfig
                        }
                    })
                } catch { }
                return response.data
            }
        }),
    })
})

export const { useGetOrderDetailsQuery } = orderApi
