import Seo from '../../components/shared/Seo';

const NotFound = () => {
    return (
        <>
            <Seo
                title="Home"
                description="Home"
                keywords="Home"
            />
            <section id="maincontent" className="pb-9">
                <div className="container">
                    <div className="row justify-content-center error-page-search">
                        <h6 className="error-page-title">Sorry, We Can't Find The Page You're Looking For.</h6>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NotFound;
