import { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/lib/styles.scss';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { replaceLoclizationLabel } from '../../../helpers/CommonHelper.js';
import { useAppDispatch } from '../../../stateManagment/reduxStore.js';
import ExpiryInput from '../../views/checkout/ExpiryInput.js';


export const CheckoutStripForm = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    validateCreditCard() {
      let error = {}

      //Initial data validation
      if (!name || name.length <= 0) {
        error.name = "Name cannot be empty";
        setValError(error);
        creditCardRef.current.scrollIntoView({ block: "center" });
        return false;
      }

      if (!number || number.length <= 0) {
        error.number = "Card Number cannot be empty";
        setValError(error);
        creditCardRef.current.scrollIntoView({ block: "center" });
        return false;
      }

      if (issuer == 'american-express') {
        if (number.length < 15) {
          error.number = "The card number you entered is too short";
          setValError(error);
          creditCardRef.current.scrollIntoView({ block: "center" });
          return false;
        }
      } else {
        if (number.length < 16) {
          error.number = "The card number you entered is too short";
          setValError(error);
          creditCardRef.current.scrollIntoView({ block: "center" });
          return false;
        }
      }

      if (!expiry || expiry.length <= 0) {
        error.expiry = "Expiry Date cannot be empty";
        setValError(error);
        creditCardRef.current.scrollIntoView({ block: "center" });
        return false;
      }

      if (expiry && expiry.length <= 3) {
        error.expiry = "Invalid Expiry Date";
        setValError(error);
        creditCardRef.current.scrollIntoView({ block: "center" });
        return false;
      }
      
      const date = new Date();
      const currentYear = date.getFullYear().toString().substring(2);
      const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      const currentMonth = months[date.getMonth()];
      const cardDate = expiry.toString().match(/\d{1,2}/g);

      if(cardDate[0] < currentMonth && cardDate[1] == currentYear) {
        error.expiry = "This Credit Card has expired";
        setValError(error);
        creditCardRef.current.scrollIntoView({ block: "center" });
        return false;
      }

      if (!cvc || cvc.length <= 0) {
        error.cvc = "CVC/CVV cannot be empty";
        setValError(error);
        creditCardRef.current.scrollIntoView({ block: "center" });
        return false;
      }

      if (issuer == 'american-express') {
        if (cvc.length < 4) {
          error.cvc = "Invalid Security Code";
          setValError(error);
          creditCardRef.current.scrollIntoView({ block: "center" });
          return false;
        }
      } else {
        if (cvc.length < 3) {
          error.cvc = "Invalid Security Code";
          setValError(error);
          creditCardRef.current.scrollIntoView({ block: "center" });
          return false;
        }
      }
      return true;
    },
    number,
    name,
    expiry,
    cvc,
    issuer
  }));

  const creditCardRef = useRef();
  const [issuer, setIssuer] = useState('');
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focused, setFocused] = useState('');
  const [valError, setValError] = useState('');
  const [isCvvModalOpen, setIsCvvModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let valueSanitized;
    switch (name) {
      case 'number':
        valueSanitized = value.replace(/[^0-9]/g, '').substring(0, 16);
        setNumber(valueSanitized);
        break;
      case 'name':
        setName(value.replace(/[^`A-Za-z\s'.-]/g, ''));
        break;
      case 'expiry':
        valueSanitized = value.replace(/[^0-9]/g, '').substring(0, 4);
        setExpiry(valueSanitized);
        break;
      case 'cvc':
        valueSanitized = value.replace(/[^0-9]/g, '').substring(0, 4);
        setCvc(valueSanitized);
        break;
      default:
        break;
    }
  };

  const toggleCvvModal = () => {
    setIsCvvModalOpen(!isCvvModalOpen);
  };

  const updateExpiryDate = (e) => {
    handleInputChange(e);
  };

  const CvvModal = (props) => {
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const {
      open,
      toggle,
      className
    } = props;
    const closeBtn = <button className="close" onClick={toggle}><span className="icon icon-close"></span></button>;

    return (
      <Modal isOpen={open} toggle={toggle} className={className} centered={true}>
        <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>
        <ModalBody>
          <p>
            {LocalizationLabelsArray ?
              replaceLoclizationLabel(LocalizationLabelsArray, "Please enter the security (CVV) code.", "lbl_check_pleaseenterthesecuritycvvcode")
              :
              "Please enter the security (CVV) code."
            }
          </p>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-6 customBorder">
                  <p className="mb-0 font-weight-bold">
                    {LocalizationLabelsArray ?
                      replaceLoclizationLabel(LocalizationLabelsArray, "Visa, MasterCard, Discover", "lbl_check_visamasterCarddiscover")
                      :
                      "Visa, MasterCard, Discover"
                    }
                  </p>
                  <p className="mb-0">
                    {LocalizationLabelsArray ?
                      replaceLoclizationLabel(LocalizationLabelsArray, "Back of Card", "lbl_check_backofcard")
                      :
                      "Back of Card"
                    }
                  </p>
                  <div className="card-cvv mastercard"></div>
                </div>
                <div className="col-sm-6 pl-sm-9">
                  <p className="mb-0 font-weight-bold">
                    {LocalizationLabelsArray ?
                      replaceLoclizationLabel(LocalizationLabelsArray, "American Express", "lbl_check_americanexpress")
                      :
                      "American Express"
                    }
                  </p>
                  <p className="mb-0">
                    {LocalizationLabelsArray ?
                      replaceLoclizationLabel(LocalizationLabelsArray, "Front of card", "lbl_check_frontofcard")
                      :
                      "Front of card"
                    }</p>
                  <div className="card-cvv amexcard"></div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <div className="credit-card-container">
      <div className="row align-items-center">
        <div className="col-md-auto order-md-2 mb-4 mb-md-0">
          <Cards
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc.replace(/[0-9]+/g, Array(cvc.length+1).join('•'))}
            focused={focused}
            callback={(type, isValid) => { setIssuer(type.issuer); }}
          />
          {/* {
            valError.length > 0 ?
              <>
                <div className="invalid-feedback d-block">{valError}</div>
              </> :
              <>
              </>
          } */}
        </div>
        <div className='col order-md-1'>
          <div className="credit-card-form needs-validation" ref={creditCardRef}>
            <div className="form-group required">
              <label htmlFor="name" className="control-label">Name on card</label>
              <input
                className="form-control required"
                type="text"
                name="name"
                placeholder="Name on card"
                value={name}
                onChange={handleInputChange}
                onFocus={(e) => {setFocused(e.target.name); setValError('');}}
              />
              {
                valError.name ?
                  <>
                    <div className="invalid-feedback d-block">{valError.name}</div>
                  </> :
                  <>
                  </>
              }
            </div>
            <div className="form-group required">
              <label htmlFor="number" className="control-label">Card Number</label>
              <input
                className="form-control"
                type="tel"
                name="number"
                maxLength="16"
                placeholder="Card Number"
                value={number}
                onChange={handleInputChange}
                onFocus={(e) => {setFocused(e.target.name); setValError('');}}
              />
              {
                valError.number ?
                  <>
                    <div className="invalid-feedback d-block">{valError.number}</div>
                  </> :
                  <>
                  </>
              }
            </div>
            <div className="row">
              <div className="form-group required col-6">
                <label htmlFor="expiry" className="control-label">Valid <br className="d-block d-sm-none"></br>Thru</label>
                <ExpiryInput className="form-control" name="expiry" onChange={handleInputChange} updateProperty={updateExpiryDate} onFocus={(e) => {setFocused(e.target.name); setValError('');}} placeholder="MM/YY Expiry" />
                {
                  valError.expiry ?
                    <>
                      <div className="invalid-feedback d-block">{valError.expiry}</div>
                    </> :
                    <>
                    </>
                }
              </div>
              <div className="form-group required col-6">
                <label htmlFor="cvc" className="control-label">
                  Security <br className="d-block d-sm-none"></br>Code
                </label>
                &nbsp;
                <Button color="link" className="tooltip-icon align-middle" onClick={toggleCvvModal} tabIndex="-1">
                  <span className="icon icon-tooltip"></span>
                </Button>
                <input
                  className="form-control"
                  type="password"
                  name="cvc"
                  placeholder="CVC"
                  value={cvc}
                  onChange={handleInputChange}
                  onFocus={(e) => {setFocused(e.target.name); setValError('');}}
                />
                {
                  valError.cvc ?
                    <>
                      <div className="invalid-feedback d-block">{valError.cvc}</div>
                    </> :
                    <>
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button className="btn btn-success" onClick={handleSubmit}>Confirm order</button> */}
      <CvvModal open={isCvvModalOpen} toggle={toggleCvvModal} className="modal-cvv" />
    </div>
  );
});
