import { Link } from 'react-router-dom';
import { getLanguageCodeFromSession } from '../../../helpers/CommonHelper';

const SiteBreadcrumb = (props) => {

    return (
        <>
            <nav className="container site-breadcrumb">
                <ul className="breadcrumb" role="navigation" aria-label="Breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={`/${getLanguageCodeFromSession()}/`}>
                            Home
                        </Link>
                    </li>
                    <li className="breadcrumb-item" dangerouslySetInnerHTML={{ __html: props.title }}></li>
                </ul>
            </nav>
        </>
    );

}

const SiteBreadcrumbV2 = ({stack = []}) => {
    return (
            <nav className="container site-breadcrumb">
                <ul className="breadcrumb" role="navigation" aria-label="Breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={`/${getLanguageCodeFromSession()}/`}>
                            Home
                        </Link>
                    </li>
                    {stack.map((item) => {
                        return (
                            <li key={item.title} className="breadcrumb-item">
                                {item.url === undefined ?
                                    item.title
                                :
                                    <Link to={item.url}>
                                        {item.title}
                                    </Link>
                                }
                            </li>
                        );
                    })}
                </ul>
            </nav>
    );

}

export { SiteBreadcrumbV2 };
export default SiteBreadcrumb;
