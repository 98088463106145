import { useEffect, useState } from 'react';
import { Alert, Card, CardBody, CardHeader, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { validateEmail } from '../../../helpers/ValidationHelper';
import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import Seo from '../../components/shared/Seo';
import { useUpdateProfileMutation, userApi } from '../../../services/user';
import { Feedback } from '../../components/feedback/Feedback';
import LoadingScreen from '../../components/shared/LoadingScreen';

const EditProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [employee, setEmployee] = useState({});
    const [UserID, setUserID] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [PhoneNo, setPhoneNo] = useState('');
    const [Email, setEmail] = useState('');
    const [EmailConfirm, setEmailConfirm] = useState('');
    const [Password, setPassword] = useState('');
    const [Login, setLogin] = useState('');
    const [feedback, setFeedback] = useState({
        message: '',
        type: '',
        open: false
    });
    const [feedbackErrors, setFeedbackErrors] = useState({})

    const loginUser = useSelector(state => state.userReducer.user);
    const LoginSystem = useSelector(state => state.commonReducer.loginSystem);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [updateProfile, { isLoading: updatingProfile }] = useUpdateProfileMutation();
    if (loginUser == null || loginUser == undefined || loginUser.UserID == undefined && loginUser.UserID < 1) {
        navigate('/' + getLanguageCodeFromSession() + '/');
    }

    const validateForm = () => {
        const errors = {}

        // Password Validation
        const regEx_Uppercase = new RegExp(/.*[A-Z]/);
        const regEx_Number = new RegExp(/.*\d/);
        const regEx_Length = new RegExp(/.{8,}$/);
        const regEx_SpecialChars = new RegExp(/.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/);

        const regEx_PasswordValid = new RegExp(`^(?=${[
            regEx_Length.source,
            regEx_Uppercase.source,
            regEx_Number.source,
            regEx_SpecialChars.source
        ].join(")(?=")}).*$`);

        // First Name
        if (!FirstName || FirstName === '') errors.FirstName = 'Please enter your Name'
        else if (FirstName.length > 15) errors.FirstName = 'First Name is too long (15 characters max)'
        else if (FirstName.match(/^[a-zA-Z-]*$/) === null) errors.FirstName = 'Please check the spelling, invalid characters detected'
        // Last Name
        if (!LastName || LastName === '') errors.LastName = 'Please enter your Last Name'
        else if (LastName.length > 20) errors.LastName = 'Last Name is too long (20 characters max)'
        else if (LastName.match(/^[a-zA-Z-]*$/) === null) errors.LastName = 'Please check the spelling, invalid characters detected'
        // Phone No
        if (!PhoneNo || PhoneNo === '') errors.PhoneNo = 'Please enter a Phone Number'
        else if (PhoneNo.length !== 10 || PhoneNo.match(/^\d+$/) === null) errors.PhoneNo = 'Phone Number must be 10 characters long (numbers only)'
        // Email
        if (!Email || Email === '') errors.Email = 'Please enter your Email'
        else if (!validateEmail(Email)) errors.Email = 'Please enter a valid Email address'
        // Email Confirm
        if (!EmailConfirm || EmailConfirm === '') errors.EmailConfirm = 'Please enter your Email'
        else if (Email !== EmailConfirm) errors.EmailConfirm = 'Email addresses do not match'
        // Password
        if (!Password || Password === '') errors.Password = 'Please enter your Password'
        else if (regEx_PasswordValid.test(Password) === false) errors.Password = 'Sorry, the provided password does not match the required constraints.'

        return errors
    }

    const removeFeedbackError = (e) => {
        delete feedbackErrors[e];
        setFeedbackErrors({ ...feedbackErrors });
    }

    const handleEditProfileForm = async (event) => {
        event.preventDefault();

        const feedbackErrors = validateForm();

        if (Object.keys(feedbackErrors).length > 0) {
            // Invalid fields
            setFeedbackErrors(feedbackErrors);
            if (document.querySelector('.is-invalid'))
                window.scrollTo({ top: document.querySelector('.is-invalid').offsetTop, behavior: 'smooth' })
        } else {
            // Valid fields
            try {
                const param = {
                    requestParameters: {
                        FirstName,
                        LastName,
                        Email,
                        PhoneNo,
                        Password,
                        AccountLogin: Login
                    }
                };
                await updateProfile(param).unwrap()
                    .then((response) => {
                        if (response.data[0].ResponseMsg === "Profile updated successfully") {
                            setFeedback({
                                type: 'success',
                                message: 'Profile updated',
                                open: true
                            });
                        } else {
                            setFeedback({
                                type: 'danger',
                                message: response.data[0].ResponseMsg,
                                open: true
                            })
                        }
                        dispatch(userApi.endpoints.me.initiate());
                    })
                    .catch((error) => {
                        if (error.data.message === 'Invalid login credentials') {
                            setFeedback({
                                type: 'danger',
                                message: 'Oops! We don’t recognize the password you entered. Please try again and remember passwords are case sensitive.',
                                open: true
                            })
                        } else {
                            setFeedback({
                                type: 'danger',
                                message: 'Error updating profile. Please, try again.',
                                open: true
                            })
                        }
                    }).finally(() => {
                        setPassword('');
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    });
            } catch (err) {
                return false;
            };
        }
    }

    useEffect(() => {
        setEmployee(loginUser);
        setUserID(loginUser.UserID);
        setFirstName(loginUser.FirstName);
        setLastName(loginUser.LastName);
        setPhoneNo(loginUser.PhoneNo);
        setEmail(loginUser.EmailAddress);
        setEmailConfirm(loginUser.EmailAddress);
        setLogin(loginUser.Login);
    }, [])

    return (
        <>
            <Seo title="Edit Profile" description="Edit customer information" keywords="Edit, Profile" />
            <SiteBreadcrumb title={"<a href='/" + getLanguageCodeFromSession() + "/my-account/'>My Account</a>" + "<span class='sep'>/</span>Edit Profile"} />
            <LoadingScreen loading={updatingProfile} />

            <section className="edit-content-page pb-8 pb-md-9" id="edit-profile">
                <div className="container-fluid ">
                    <div className="section-title d-flex align-items-center justify-content-between justify-content-md-center position-relative mt-md-8 mb-5">
                        <h1 className="h2 mb-0">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Profile", "lbl_myaccount_profile")
                                :
                                "Profile"
                            }
                        </h1>
                        <Link to={`/${getLanguageCodeFromSession()}/my-account`} className="my-account-link btn-link link-underline">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Back to My Account", "lbl_myaccount_backtomyaccount")
                                :
                                "Back to My Account"
                            }
                        </Link>
                    </div>

                    <Alert color={feedback.type} isOpen={feedback.open}>
                        {feedback.message}
                    </Alert>

                    <div className="row">
                        <div className="col-12">
                            <Card color='light' className='card-my-account border-0'>
                                <CardHeader tag='h5' className='no-bg-header border-bottom-0 pt-md-6'>
                                    {LocalizationLabelsArray.length > 0 ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "Edit Profile", "lbl_myaccount_editprofile")
                                        :
                                        "Edit Profile"
                                    }
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleEditProfileForm} noValidate autoComplete='off'>
                                        <FormGroup className='required'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "First Name", "lbl_check_fname")
                                                    :
                                                    "First Name"
                                                }
                                            </Label>
                                            <Input type='text' name='FirstName' id='FirstName' maxLength='15' value={FirstName}
                                                onChange={(event) => setFirstName(event.target.value)}
                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                onBlur={() => setFeedbackErrors(validateForm())}
                                                invalid={!!feedbackErrors.FirstName}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.FirstName}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Last Name", "lbl_check_lname")
                                                    :
                                                    "Last Name"
                                                }
                                            </Label>
                                            <Input type='text' name='LastName' id='LastName' maxLength='20' value={LastName}
                                                onChange={(event) => setLastName(event.target.value)}
                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                onBlur={() => setFeedbackErrors(validateForm())}
                                                invalid={!!feedbackErrors.LastName}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.LastName}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Email Address", "lbl_check_emailaddress")
                                                    :
                                                    "Email Address"
                                                }
                                            </Label>
                                            <Input type='email' name='EmailAddress' id='EmailAddress' disabled={LoginSystem === 1} value={Email}
                                                onChange={(event) => { setEmail(event.target.value); setEmailConfirm('') }}
                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                onBlur={() => setFeedbackErrors(validateForm())}
                                                invalid={!!feedbackErrors.Email}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.Email}</FormFeedback>
                                        </FormGroup>

                                        {LoginSystem !== 1 ?
                                            <FormGroup className='required'>
                                                <Label>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Confirm Email Address", "lbl_check_confirmemailaddress")
                                                        :
                                                        "Confirm Email Address"
                                                    }
                                                </Label>
                                                <Input type='text' name='EmailConfirm' id='EmailConfirm' value={EmailConfirm}
                                                    onChange={(event) => setEmailConfirm(event.target.value)}
                                                    onFocus={(event) => removeFeedbackError(event.target.id)}
                                                    onBlur={() => setFeedbackErrors(validateForm())}
                                                    invalid={!!feedbackErrors.EmailConfirm}
                                                >
                                                </Input>
                                                <FormFeedback type='invalid'>{feedbackErrors.EmailConfirm}</FormFeedback>
                                            </FormGroup>
                                            : <></>
                                        }

                                        <FormGroup className='required'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Phone Number", "lbl_check_phonenumber")
                                                    :
                                                    "Phone Number"
                                                }
                                            </Label>
                                            <Input type='tel' name='PhoneNo' id='PhoneNo' maxLength='10' value={PhoneNo}
                                                onChange={(event) => setPhoneNo(event.target.value)}
                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                onBlur={() => setFeedbackErrors(validateForm())}
                                                invalid={!!feedbackErrors.PhoneNo}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.PhoneNo}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label className='mb-0'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Password", "lbl_check_password")
                                                    :
                                                    "Password"
                                                }
                                            </Label>
                                            <p className='mb-2'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Enter your current password to save changes to your profile & contact information.", "lbl_myaccount_enterpasswordmessage")
                                                    :
                                                    "Enter your current password to save changes to your profile & contact information."
                                                }
                                            </p>
                                            <Input type='password' name='password' id='password' value={Password} required={true}
                                                onChange={(e) => setPassword(e.target.value)}
                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                onBlur={() => setFeedbackErrors(validateForm())}
                                                invalid={!!feedbackErrors.Password}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.Password}</FormFeedback>
                                        </FormGroup>

                                        <div className='row'>
                                            <div className="col-12 col-md mb-4 mb-md-0">
                                                <button type="submit" name="save" className="btn btn-save btn-block btn-primary">
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Save", "lbl_myaccount_save")
                                                        :
                                                        "Save"
                                                    }
                                                </button>
                                            </div>
                                            <div className="col-12 col-md">
                                                <Link to={`/${getLanguageCodeFromSession()}/my-account`} className="btn btn-outline-primary bg-white w-100">
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Cancel", "lbl_myaccount_cancel")
                                                        :
                                                        "Cancel"
                                                    }
                                                </Link>
                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default EditProfile;
