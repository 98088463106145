import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export const Feedback = ({ open, message, type, toggle }) => {
    // const [isOpen, setIsOpen] = useState(null);

    return (
        <Modal
            contentClassName={type === 'error' ? 'alert alert-danger p-0' : 'alert alert-success p-0'}
            isOpen={open}
            toggle={toggle}
            backdrop={false}
        >
            <ModalHeader className="p-0" toggle={toggle} />
            <ModalBody
                className={type === 'error' ? 'alert alert-danger m-0' : 'alert alert-success m-0'}
            >
                {message}
            </ModalBody>
        </Modal>
    );
}
