
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate, Navigate } from 'react-router-dom';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel, setLanguageCodeInSession, IsValidSession } from '../../../helpers/CommonHelper';
import { makeProductShortDescription } from '../../../helpers/ConversionHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import rootAction from '../../../stateManagment/actions/rootAction';
import Wishlist from '../modal/Wishlist';



const TopHeader = () => {
    const [display, setDisplay] = useState(false);
    const [langCode, setLangCode] = useState('');
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const loggedInUser = useSelector(state => state.userReducer.user);

    const handleWishlist = () => {
        setDisplay(!display);
    }

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["TopHeader"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])

    return (
        <>
            <div id="top-menu" className="top-menu container hidden-sm-down py-3">
                <nav className="header-links d-flex justify-content-end">
                    {
                    loggedInUser.UserID
                    ?
                    <>
                    <Link to={`/${getLanguageCodeFromSession()}/orders-history`} id="lbl_account_orderhistory">
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "Order History", "lbl_account_orderhistory")
                            :
                            "Order History"
                        }
                    </Link>
                    </>
                    :
                    <>
                    </>
                    }
                    {loggedInUser.UserID &&(
                    <Fragment>
                    <Link to={`/${getLanguageCodeFromSession()}/contact-us`} id="lbl_thead_help">
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "Help", "lbl_thead_help")
                            :
                            "Help"
                        }
                    </Link>
                    <Link to={`/${getLanguageCodeFromSession()}/faq`} id="lbl_thead_faq">
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, " FAQs", "lbl_thead_faq")
                            :
                            "FAQs"
                        }
                    </Link>
                    {display ? <Wishlist handleWishlist={handleWishlist} /> : ''}
                    </Fragment>)}
                </nav>
            </div>
        </>
    );

}


export default TopHeader;