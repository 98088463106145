import React from 'react';
import { api } from '../../../services/api';
import { useSelector } from 'react-redux';

const GoTop = () => {

    const [thePosition, setThePosition] = React.useState(false);

    React.useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                setThePosition(true)
            } else {
                setThePosition(false);
            }
        });
    }, [])

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const renderGoTopIcon = () => {
        if (thePosition) {
            return (
                <button className={`back-to-top`} onClick={scrollToTop}>
                    <span className="icon icon-arrowUpWhite"></span>
                    <span>TOP</span>
                </button>
            )
        }
    }

    return  renderGoTopIcon();
}

export default GoTop;