import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOADER_DURATION } from '../../helpers/Constants';
import { siteApi } from '../../services/site';
import { contentApi } from '../../services/content';
import _ from 'lodash';

const wait = () =>
    new Promise((resolve) => {
        setTimeout(() => resolve(), LOADER_DURATION);
    });

export const stopLoading = createAsyncThunk(
    'common/stopLoading',
    async () => {
        await wait();
        return true;
    }
)

const initialState = {
    siteId: '',
    timeout: null,
    loading: false,
    websiteLogoInLocalStorage: "",
    urlStack: [],
    langCode: 'en',
    siteName: "Basic B2B",
    isChatEnabled: false,
    isSubmitOrderEnabled: true,
    selfRegister: false,
    loginSystem: 1,
    minicartDisabled: false,
    homeBanner: {
        isLoading: false,
        slides: []
    },
    localeData: {
        'es': [],
    },
    siteLocations: [],
    siteDefaultLocation: null,
    isPBEEnabled: false,
    isQualtricsEnabled: false,
    displayPBERecipientListOnPDP: false,
    shipToLocationEnabledForUser: false,
    shipToLocationEnabled: false,
    isPayrollDeductionEnabled: false,
    smartyStreets: {
        acSettings: {},
        avsSettings: {},
    },
    billingAddressEditable: false,
    shippingAddressEditable: false,
    retokenizing: false,
    sessionExpired: false,
    sessionExpiringSoon: false,
    currentCategoryId: null,
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    lastVisitedUrl: null,
    embChoiceDisplayDefault: 1,
    embChoiceDisplayGreaterThan: 3,
    embChoiceDisplayGreaterThanOverride: 2,
    embChoiceDropdownDefaultOptionText: '',
    minSizeOptionsForDropdown: 20,
    trackingLink: '',
    additionalSettings: {},
    sizingPortalEnabled : false,
    enableSSO: false,
    samlLogoutEndpoint:'',
    samlLoginEndpoint:'',
    orderConfirmationMessage:'',
    allotmentData: {},
    isPersonalInfoModificationAllowed : true
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setAllotmentData: (state, action) => {
            state.allotmentData = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setWebsiteLogo: (state, action) => {
            state.websiteLogoInLocalStorage = action.payload;
        },
        setLangCodeInRedux: (state, action) => {
            state.langCode = action.payload;
        },
        setUserShipToLocationEnabled: (state, action) => {
            state.shipToLocationEnabledForUser = action.payload;
        },
        disableMiniCart: (state, action) => {
            state.minicartDisabled = action.payload;
        },
        isRetokenizing: (state, action) => {
            state.retokenizing = action.payload;
        },
        sessionExpiringSoon: (state, action) => {
            state.sessionExpiringSoon = action.payload;
        },
        sessionExpired: (state, action) => {
            state.sessionExpired = action.payload;
        },
        setCurrentCategoryId: (state, action) => {
            state.currentCategoryId = action.payload;
        },
        setLastVisitedUrl: (state, action) => {
            state.lastVisitedUrl = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(stopLoading.pending, (state) => {
                state.loading = true;
            })
            .addCase(stopLoading.fulfilled, (state) => {
                state.loading = false;
            })
            .addMatcher(siteApi.endpoints.getSiteLogo.matchFulfilled, (state, action) => {
                state.websiteLogoInLocalStorage = action.payload.url;
            })
            .addMatcher(siteApi.endpoints.getSiteInfo.matchFulfilled, (state, action) => {
                state.siteId = action.payload.id;
                state.siteName = action.payload.name;
                state.isChatEnabled = action.payload.isChatEnabled;
                state.isSubmitOrderEnabled = action.payload.isSubmitOrderEnabled;
                state.selfRegister = action.payload.selfRegister;
                state.loginSystem = action.payload.loginSystem;
                state.isPBEEnabled = action.payload.pbeEnabled;
                state.isQualtricsEnabled = action.payload.qualtricsEnabled;
                state.sizingPortalEnabled = action.payload.sizingPortalEnabled;
                state.displayPBERecipientListOnPDP = action.payload.displayPBERecipientListOnPDP;
                state.shipToLocationEnabled = action.payload.shipToLocationEnabled;
                state.siteLocations = action.payload.allLocations;
                state.siteDefaultLocation = action.payload.defaultLocation;
                state.isPayrollDeductionEnabled = action.payload.isPayrollDeductionEnabled;
                state.smartyStreets = {
                    acSettings: action.payload.smartyACSettings,
                    avsSettings: action.payload.smartyAVSSettings,
                };
                state.billingAddressEditable = action.payload.billingAddressEditable;
                state.shippingAddressEditable = action.payload.shippingAddressEditable;
                state.embChoiceDisplayDefault = action.payload.embChoiceDisplayDefault;
                state.embChoiceDisplayGreaterThan = action.payload.embChoiceDisplayGreaterThan;
                state.embChoiceDisplayGreaterThanOverride = action.payload.embChoiceDisplayGreaterThanOverride;
                state.embChoiceDropdownDefaultOptionText = action.payload.embChoiceDropdownDefaultOptionText;
                state.categories = action.payload.categories;
                state.trackingLink = action.payload.trackingLink;
                state.additionalSettings = action.payload.additionalSettings;
                state.enableSSO = action.payload.enableSSO;
                state.samlLogoutEndpoint = action.payload.samlLogoutEndpoint;
                state.samlLoginEndpoint = action.payload.samlLoginEndpoint;
                state.orderConfirmationMessage = action.payload.orderConfirmationMessage;
                state.isShippingTaxExempt = action.payload.isShippingTaxExempt;
                state.isTaxExemptAppliedToMaxItem = action.payload.isTaxExemptAppliedToMaxItem;
                state.listIgnoreTaxExempt = action.payload.listIgnoreTaxExempt;
                state.shipToLocationAddressFallback = action.payload.shipToLocationAddressFallback;
                state.payrollDeductionSelectOptions = action.payload.payrollDeductionSelectOptions;
                state.enableSSO = action.payload.enableSSO;
                state.samlLogoutEndpoint = action.payload.samlLogoutEndpoint;
                state.samlLoginEndpoint = action.payload.samlLoginEndpoint;
                state.isPersonalInfoModificationAllowed = action.payload.isPersonalInfoModificationAllowed;
            })
            .addMatcher(contentApi.endpoints.getHomeBanner.matchFulfilled, (state) => {
                state.homeBanner.isLoading = true;
            })
            .addMatcher(contentApi.endpoints.getHomeBanner.matchRejected, (state) => {
                state.homeBanner.isLoading = false;
            })
            .addMatcher(contentApi.endpoints.getHomeBanner.matchFulfilled, (state, action) => {
                state.homeBanner.isLoading = false;
                state.homeBanner.slides = action.payload;
            })
            .addMatcher(siteApi.endpoints.getLocaleData.matchFulfilled, (state, action) => {
                state.localeData = {
                    ...action.payload,
                }
            })
    }
})

export const { setUser, logOutUser } = commonSlice.actions;
export const commonActions = commonSlice.actions;
export default commonSlice.reducer;
