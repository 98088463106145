import ContentPage from '../../components/layout/ContentPage';
import BannerSlider from '../../components/home/BannerSlider'
import Seo from '../../components/shared/Seo';
import { useGetHomeBannerQuery, useGetContentAssetQuery } from '../../../services/content';

const Home = () => {
    useGetHomeBannerQuery();
    const { data: homePageGreeting } = useGetContentAssetQuery('homePageGreeting');

    const handleBannerButtonClickUrl = (url) => {
        if (url != undefined && url != null && url.length > 0) {
            window.location.href = url;
        } else {
            return false;
        }
    }

    return (
        <ContentPage title={"Home"} contentId={"homepage"} contentFull={false}>
            <Seo title="Home" description="Home" keywords="Home" />

            {homePageGreeting?.ContentValue?.length > 0 &&
                <div dangerouslySetInnerHTML={{ __html: homePageGreeting?.ContentValue }}></div>
            }
            <BannerSlider handleBannerButtonClickUrl={handleBannerButtonClickUrl} />
        </ContentPage>
    );
}

export default Home;
