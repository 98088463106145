import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingWrapper = ({ isLoading, children }) => {
    return (
        <div className="loading-wrapper position-relative">
            <div className='position-absolute w-100 h-100 justify-content-center align-items-center' style={{ display: isLoading ? 'flex' : 'none' }}>
                <Spinner color='success' />
            </div>
            {children}
        </div>
    );
}

export default LoadingWrapper;
