import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import rootAction from '../../../stateManagment/actions/rootAction';
import { LOADER_DURATION } from '../../../helpers/Constants';
import Seo from '../../components/shared/Seo';



//-- This page is only for refreshing the data like login user, site logo etc
const Refresh = () => {
    const dispatch = useDispatch();

    const [siteTitle, setSiteTitle] = useState(useSelector(state => state.commonReducer.siteName));



    useEffect(() => {
        // declare the data fetching function
        const dataOperationInUseEffect = async () => {


            //--Clear user data from redux
            dispatch(rootAction.userAction.setUser("{}"));
            localStorage.setItem("user", "{}");

            //--clear customer cart
            dispatch(rootAction.cartAction.setCustomerCart('[]'));
            dispatch(rootAction.cartAction.SetTotalCartItems(0));
            localStorage.setItem("cartItems", "[]");

            //--Clear Logo
            dispatch(rootAction.commonAction.setWebsiteLogo(""));

            //--stop loader
            setTimeout(() => {
                window.location.href = "/";
            }, LOADER_DURATION);
        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        dataOperationInUseEffect().catch(console.error);

        //--stop loader
        dispatch(rootAction.commonAction.stopLoading());
    }, [])



    return (
        <>
            <Seo title="Refresh" description="Refresh" keywords="Refresh" />

            <SiteBreadcrumb title="Refresh" />

            <section className="about-area ptb-60">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">

                                <p>All data refreshed successfully.</p>



                            </div>
                        </div>


                    </div>
                </div>
            </section>



            <BestFacilities />

        </>
    );

}

export default Refresh;
