import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Alert, Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, FormFeedback, Label, Input, Spinner } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux';
import { showErrorMsg, validateZipCode } from '../../../helpers/ValidationHelper';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import Seo from '../../components/shared/Seo';
import { useGetAddressesQuery, useUpdateAddressesMutation } from '../../../services/user';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLazyGetSmartySuggestionsQuery } from "../../../services/site";
import debounce from 'lodash.debounce';

const UpdateProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [employee, setEmployee] = useState({});
    const [UserID, setUserID] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [MobileNo, setMobileNo] = useState('');
    const [AddressLineOne, setAddressLineOne] = useState('');
    const [PostalCode, setPostalCode] = useState('');
    const [StateProvinceId, setStateProvinceId] = useState('');
    const [CityId, setCityId] = useState('');
    const [StatesProvincesList, setStatesProvincesList] = useState([]);
    const [CitiesList, setCitiesList] = useState([]);
    const [updateAddresses, { isLoading: isUpdating }] = useUpdateAddressesMutation();
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [feedback, setFeedback] = useState({ message: '', type: '', open: false });
    const loginUser = useSelector(state => state.userReducer.user);
    const { data: addresses, isFetching: addressesLoading } = useGetAddressesQuery(loginUser?.UserID ? undefined : skipToken);
    const [CustBillingAddress, setCustBillingAddress] = useState({ id: "billing", c_axAddressType: "B", firstName: "", lastName: "", address1: "", address2: "", city: "", stateCode: "", countryCode: "US", postalCode: "", phone: "" });
    const [InputCustShippingAddress, setInputCustShippingAddress] = useState({ id: "shipping", c_axAddressType: "S", firstName: "", lastName: "", address1: "", address2: "", city: "", stateCode: "", countryCode: "US", postalCode: "", phone: "" });
    const [CustShippingAddress, setCustShippingAddress] = useState({ id: "shipping", c_axAddressType: "S", firstName: "", lastName: "", address1: "", address2: "", city: "", stateCode: "", countryCode: "US", postalCode: "", phone: "" });
    const [CartCalcMessage, setCartCalcMessage] = useState('');

    const [openSuggestDropdown, setOpenSuggestDropdown] = useState(false);
    const [smartySuggestionDropdown, setSmartySuggestionDropdown] = useState([]);
    const [openSuggestShippingDropdown, setOpenSuggestShippingDropdown] = useState(false);
    const [smartySuggestionShippingDropdown, setSmartySuggestionShippingDropdown] = useState([]);
    const [smartySuggest, { isFetching: isFetchingSuggestions }] = useLazyGetSmartySuggestionsQuery();
    const { smartyStreets: { acSettings: { c_enableSmartyStreetsAutoComplete: ssACEnabled }, avsSettings: { c_enableSmartyStreetsAddressValidation: ssAVSEnabled } } } = useSelector((state) => state.commonReducer);

    const [visibleAlert, setVisibleAlert] = useState(true);
    const dismissAlert = () => setVisibleAlert(false);

    const [feedbackErrors, setFeedbackErrors] = useState({});

    const [searchParams] = useSearchParams();
    const qsParsed = { redircode: searchParams.get('redircode'), }

    const { acSettings, avsSettings } = useSelector(state => state.commonReducer.smartyStreets);
    const { billingAddressEditable, shippingAddressEditable } = useSelector((state) => state.commonReducer);
    const isPersonalInfoModificationAllowed = useSelector(state => state.commonReducer.isPersonalInfoModificationAllowed);

    const validateField = (type, key = null) => {
        const validateKeys = key ? [key] : ['firstName', 'lastName', 'address1', 'city', 'stateCode', 'countryCode', 'postalCode', 'phone'];

        switch (type) {
            case 'billing':
                validateKeys.forEach(key => {
                    CustBillingAddress[key] = CustBillingAddress[key]?.trim();
                    if (key === 'firstName' || key === 'lastName') {
                        if (CustBillingAddress[key]?.length === 0) {
                            feedbackErrors['billing_' + key] = 'Please fill out this field.';
                        } else if (CustBillingAddress[key]?.match(/^[a-zA-Z-]*$/) === null) {
                            feedbackErrors['billing_' + key] = 'Please check the spelling, invalid characters detected'
                        }
                    } else if (key === 'phone') {
                        if (CustBillingAddress[key]?.length === 0) {
                            feedbackErrors['billing_' + key] = 'Please enter a Phone Number.';
                        } else if (CustBillingAddress[key]?.length != 10 || CustBillingAddress[key]?.match(/^\d+$/) === null) {
                            feedbackErrors['billing_' + key] = 'Phone Number must be 10 characters long (numbers only).'
                        }
                    } else if (key === 'countryCode' || key === 'stateCode') {
                        if (CustBillingAddress[key]?.length === 0) {
                            feedbackErrors['billing_' + key] = 'Please select an option.'
                        }
                    } else if (key === 'postalCode') {
                        if (!validateZipCode(CustBillingAddress[key])) {
                            feedbackErrors['billing_' + key] = 'Please enter a valid zip code.';
                        }
                    } else {
                        if (CustBillingAddress[key]?.length === 0) {
                            feedbackErrors['billing_' + key] = 'Please fill out this field.'
                        }
                    }
                })
                break;

            case 'shipping':
                validateKeys.forEach(key => {
                    CustShippingAddress[key] = CustShippingAddress[key]?.trim();
                    if (key === 'firstName' || key === 'lastName') {
                        if (CustShippingAddress[key]?.length === 0) {
                            feedbackErrors['shipping_' + key] = 'Please fill out this field.';
                        } else if (CustShippingAddress[key]?.match(/^[a-zA-Z-]*$/) === null) {
                            feedbackErrors['shipping_' + key] = 'Please check the spelling, invalid characters detected'
                        }
                    } else if (key === 'phone') {
                        if (CustShippingAddress[key]?.length === 0) {
                            feedbackErrors['shipping_' + key] = 'Please enter a Phone Number.';
                        } else if (CustShippingAddress[key]?.length != 10 || CustShippingAddress[key]?.match(/^\d+$/) === null) {
                            feedbackErrors['shipping_' + key] = 'Phone Number must be 10 characters long (numbers only).'
                        }
                    } else if (key === 'countryCode' || key === 'stateCode') {
                        if (CustShippingAddress[key]?.length === 0) {
                            feedbackErrors['shipping_' + key] = 'Please select an option.'
                        }
                    } else if (key === 'postalCode') {
                        if (!validateZipCode(CustShippingAddress[key])) {
                            feedbackErrors['shipping_' + key] = 'Please enter a valid zip code.';
                        }
                    } else {
                        if (CustShippingAddress[key]?.length === 0) {
                            feedbackErrors['shipping_' + key] = 'Please fill out this field.'
                        }
                    }
                })
                break

            default:
                break;
        }

        setFeedbackErrors({ ...feedbackErrors });
    }

    const removeFeedbackError = (type, key = null) => {
        const removeKeys = key ? [key] : ['firstName', 'lastName', 'address1', 'city', 'stateCode', 'countryCode', 'postalCode', 'phone'];

        removeKeys.forEach(key => {
            delete feedbackErrors[type + '_' + key];
        });
        setFeedbackErrors({ ...feedbackErrors });
    }

    const [sameAsBillingAddress, setSameAsBillingAddress] = useState(false);

    useEffect(() => {
        if (sameAsBillingAddress) {
            setCustShippingAddress({ ...CustBillingAddress, id: CustShippingAddress.id, c_axAddressType: CustShippingAddress.c_axAddressType });
            removeFeedbackError('shipping');
        } else {
            setCustShippingAddress({ ...InputCustShippingAddress })
        }
    }, [sameAsBillingAddress])

    const handleBillingChange = (e) => {
        setCustBillingAddress({ ...CustBillingAddress, [e.target.name]: e.target.value });
        if (sameAsBillingAddress) {
            setCustShippingAddress({
                ...CustBillingAddress,
                id: CustShippingAddress.id,
                c_axAddressType: CustShippingAddress.c_axAddressType,
                [e.target.name]: e.target.value
            })
        }
    }

    const suggestBillingAddressDropdown = (e) => {
        if (ssACEnabled && e.target.value.length > 2) {
            smartySuggest({ search: e.target.value }).unwrap().then((res) => {
                if (res.data?.response.length > 0) {
                    setSmartySuggestionDropdown(res.data?.response);
                }
                setOpenSuggestDropdown(res.data?.response.length > 0);

            });
        }
    }

    const debouncedSuggestBillingAddressDropdown = useMemo(
        () => {
            return debounce(suggestBillingAddressDropdown, 1000)
        }, []);

    const selectSmartyBillingAddress = (item) => {
        setCustBillingAddress({
            ...CustBillingAddress,
            address1: item.street_line,
            address2: '',
            city: item.city,
            stateCode: item.state,
            postalCode: item.zipcode
        });
        if (sameAsBillingAddress) {
            setCustShippingAddress({
                ...CustBillingAddress,
                id: CustShippingAddress.id,
                c_axAddressType: CustShippingAddress.c_axAddressType,
                address1: item.street_line,
                address2: '',
                city: item.city,
                stateCode: item.state,
                postalCode: item.zipcode
            });
        }
        setSmartySuggestionDropdown([]);
    }

    const selectSmartyShippingAddress = (item) => {
        setCustShippingAddress({
            ...CustShippingAddress,
            address1: item.street_line,
            address2: '',
            city: item.city,
            stateCode: item.state,
            postalCode: item.zipcode
        });
        setSmartySuggestionShippingDropdown([]);

    }

    const toggleSuggestDropdown = () => {
        setOpenSuggestDropdown(ssAVSEnabled && !openSuggestDropdown);
    }

    const toggleSuggestShippingDropdown = () => {
        setOpenSuggestShippingDropdown(ssAVSEnabled && !openSuggestShippingDropdown);
    }

    const handleShippingChange = (e) => {
        if (sameAsBillingAddress) return;

        setInputCustShippingAddress({ ...InputCustShippingAddress, [e.target.name]: e.target.value });
        setCustShippingAddress({ ...CustShippingAddress, [e.target.name]: e.target.value });
    }

    const suggestAddressDropdown = async (e) => {
        if (ssACEnabled && e.target.value.length > 2) {
            smartySuggest({ search: e.target.value }).unwrap().then((res) => {
                if (res.data?.response.length > 0) {
                    setSmartySuggestionShippingDropdown(res.data?.response);
                }
                setOpenSuggestShippingDropdown(res.data?.response.length > 0);
            });
        }
    }

    const debouncedSuggestAddressDropdown = useMemo(
        () => {
            return debounce(suggestAddressDropdown, 1000)
        }, []);


    if (loginUser == null || loginUser == undefined || loginUser.UserID == undefined && loginUser.UserID < 1) {
        navigate('/' + getLanguageCodeFromSession() + '/');
    }

    const HandleStateProvinceChagne = async (value) => {
        if (value != undefined) {
            setStateProvinceId(value);

            //--load city data when state changed
            await LoadCitiesData(value);
        }

    }

    const LoadCitiesData = async (StateValue) => {
        let headersCity = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        let paramCity = {
            requestParameters: {
                StateProvinceId: StateValue ?? StateProvinceId,
                recordValueJson: "[]",
            },
        };

        //--Get cities list
        let responseCities = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CITIES_LIST'], null, paramCity, headersCity, "POST", true);
        if (responseCities != null && responseCities.data != null) {
            setCitiesList(JSON.parse(responseCities.data.data));

        }
    }

    const LoadStateProvinceData = async () => {
        let headersStateProvince = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        let paramStateProvince = {
            requestParameters: {
                recordValueJson: "[]",
            },
        };

        //--Get state province list
        let responseStatesProvince = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_STATES_PROVINCES_LIST'], null, paramStateProvince, headersStateProvince, "POST", true);
        if (responseStatesProvince != null && responseStatesProvince.data != null) {
            setStatesProvincesList(JSON.parse(responseStatesProvince.data.data));
        }
    }

    const handleUpdateProfileForm = async (event) => {
        event.preventDefault();
        // Clean errors before validating
        removeFeedbackError('billing');
        removeFeedbackError('shipping');

        // Validate Fields
        validateField('billing');
        // Do not collect Shipping form errors if it is same as Billing
        if (!sameAsBillingAddress) {
            validateField('shipping');
        }

        if (Object.keys(feedbackErrors).length > 0) {
            // Invalid fields
            var element = document.querySelector('.is-invalid');
            if (element) {
                window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
            }
        } else {
            // Valid fields
            try {
                const param = {
                    billingAddress: CustBillingAddress,
                    shippingAddress: CustShippingAddress,
                    email: loginUser.EmailAddress || '',
                    locationNumber: loginUser.EmployeeLocation || '0'
                };

                await updateAddresses(param)
                    .unwrap()
                    .then((data) => {
                        setFeedback({
                            type: 'success',
                            message: 'Profile updated',
                            open: true
                        });
                        setCustBillingAddress({
                            ...data.billingAddress
                        });
                        setCustShippingAddress({
                            ...data.shippingAddress
                        });
                        dispatch(rootAction.checkoutActions.setBillingAddress(null));
                        dispatch(rootAction.checkoutActions.setShippingAddress(null));

                        if (qsParsed && qsParsed.redircode && qsParsed.redircode === "1") {
                            navigate(`/${getLanguageCodeFromSession()}/checkout`);
                        }
                    })
                    .catch((error) => {
                        showErrorMsg(error.data.message);
                        setFeedback({
                            type: 'danger',
                            message: error.data.message,
                            open: true
                        });
                    })

            } catch (err) {
                console.log(err);
                showErrorMsg("An error occured. Please try again!");

                return false;

            } finally {
                //--stop loader
                dispatch(rootAction.commonAction.stopLoading());
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

        }
    }

    useEffect(() => {
        if (!addressesLoading) {
            setCustBillingAddress({
                ...addresses.billingAddress,
                countryCode: addresses.billingAddress.countryCode?.length > 0 ? addresses.billingAddress.countryCode : 'US',
                id: CustBillingAddress.id || 'billing',
                c_axAddressType: CustBillingAddress.c_axAddressType || 'B'
            });
            setCustShippingAddress({
                ...addresses.shippingAddress,
                countryCode: addresses.shippingAddress.countryCode?.length > 0 ? addresses.shippingAddress.countryCode : 'US',
                id: CustShippingAddress.id || 'shipping',
                c_axAddressType: CustShippingAddress.c_axAddressType || 'S'
            });
        }
    }, [addressesLoading]);

    useEffect(() => {
        setEmployee(loginUser);
        setUserID(loginUser.UserID);
        setFirstName(loginUser.FirstName);
        setLastName(loginUser.LastName);
        setMobileNo(loginUser.MobileNo);
        setAddressLineOne(loginUser.AddressLineOne);
        setStateProvinceId(loginUser.StateProvinceID);
        setCityId(loginUser.CityID);
        setPostalCode(loginUser.PostalCode);
    }, [])


    useEffect(() => {
        // parse the query string and check if a message needs to be diplayed
        if (qsParsed && qsParsed.redircode && qsParsed.redircode === "1") {
            setCartCalcMessage("You were redirected from cart/checkout because your profile doesn’t have a billing and/or shipping address. Setup your prefered billing and/or shipping address and navigate back to cart/checkout.");
        }
    }, [qsParsed])

    return (
        <>
            <Seo title="Address Book" description="Address Book" keywords="Address Book" />
            <SiteBreadcrumb title={"<a href='/" + getLanguageCodeFromSession() + "/my-account/'>My Account</a>" + "<span class='sep'>/</span>Address Book"} />
            <LoadingScreen loading={isUpdating || addressesLoading} />

            <section className="edit-content-page pb-8 pb-md-9" id="edit-password">
                <div className="container-fluid ">
                    <div className="section-title d-flex align-items-center justify-content-between justify-content-md-center position-relative mt-md-8 mb-5">
                        <h1 className="h2 mb-0">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Address Book", "lbl_myaccount_addressbook")
                                :
                                "Address Book"
                            }
                        </h1>
                        <Link to={`/${getLanguageCodeFromSession()}/my-account`} className="my-account-link btn-link link-underline">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Back to My Account", "lbl_myaccount_backtomyaccount")
                                :
                                "Back to My Account"
                            }
                        </Link>
                    </div>

                    {CartCalcMessage ?
                        <>
                            <Alert color="info" isOpen={visibleAlert}>
                                {CartCalcMessage}
                            </Alert>
                        </>
                        :
                        <></>
                    }

                    <Alert color={feedback.type} isOpen={feedback.open}>
                        {feedback.message}
                    </Alert>

                    <div className="row">
                        <div className="col-12">
                            <Form onSubmit={handleUpdateProfileForm} noValidate>
                                <Card color='light' className='card-my-account border-0'>
                                    <CardHeader tag='h5' className='no-bg-header border-bottom-0 pt-md-6'>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Billing Address", "lbl_myaccount_billingaddress")
                                            :
                                            "Billing Address"
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup className='required'>
                                            <Label for='BillingFirstName'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "First Name", "lbl_check_fname")
                                                    :
                                                    "First Name"
                                                }
                                            </Label>
                                            <Input type='text' name='firstName' id='BillingFirstName' maxLength='15'
                                                value={CustBillingAddress.firstName}
                                                onChange={handleBillingChange}
                                                onFocus={(event) => removeFeedbackError('billing', event.target.name)}
                                                onBlur={(event) => validateField('billing', event.target.name)}
                                                invalid={!!feedbackErrors.billing_firstName}
                                                disabled={!billingAddressEditable || !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.billing_firstName}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='BillingLastName'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Last Name", "lbl_check_lname")
                                                    :
                                                    "Last Name"
                                                }
                                            </Label>
                                            <Input type='text' name='lastName' id='BillingLastName' maxLength='20'
                                                value={CustBillingAddress.lastName}
                                                onChange={handleBillingChange}
                                                onFocus={(event) => removeFeedbackError('billing', event.target.name)}
                                                onBlur={(event) => validateField('billing', event.target.name)}
                                                invalid={!!feedbackErrors.billing_lastName}
                                                disabled={!billingAddressEditable || !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.billing_lastName}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='BillingAddress1'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Address 1", "lbl_check_address1")
                                                    :
                                                    "Address 1"
                                                }
                                            </Label>
                                            <Dropdown isOpen={openSuggestDropdown} toggle={toggleSuggestDropdown}>
                                                <DropdownToggle tag="div">
                                                    <Input type='text' name='address1' id='address1' maxLength='35'
                                                        value={CustBillingAddress.address1 || ''}
                                                        onChange={(e) => { handleBillingChange(e); debouncedSuggestBillingAddressDropdown(e); }}
                                                        invalid={!!feedbackErrors.billing_address1}
                                                        autoComplete={ssACEnabled ? "address1" : ""}
                                                        disabled={!billingAddressEditable || !isPersonalInfoModificationAllowed}
                                                    />
                                                    <FormFeedback type='invalid'>{feedbackErrors.billing_address1}</FormFeedback>
                                                </DropdownToggle>
                                                {smartySuggestionDropdown.length > 0 && <DropdownMenu>
                                                    {smartySuggestionDropdown?.map((item) => {
                                                        return (
                                                            <DropdownItem onClick={() => selectSmartyBillingAddress(item)}>{item.asString}</DropdownItem>
                                                        )
                                                    })}
                                                </DropdownMenu>}
                                                {isFetchingSuggestions && <Spinner className="position-absolute" style={{ right: 10, top: 10, left: 'auto' }} />}
                                            </Dropdown>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for='BillingAddress2'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Address 2 (optional)", "lbl_check_address2optional")
                                                    :
                                                    "Address 2 (optional)"
                                                }
                                            </Label>
                                            <Input type='text' name='address2' id='BillingAddress2' maxLength='35'
                                                value={CustBillingAddress.address2}
                                                onChange={handleBillingChange}
                                                autoComplete={ssACEnabled ? "address2" : ""}
                                                disabled={!billingAddressEditable || !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='BillingCountry'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Country", "lbl_check_country")
                                                    :
                                                    "Country"
                                                }
                                            </Label>
                                            <Input type='select' name='countryCode' id='BillingCountry' className='custom-select'
                                                value={CustBillingAddress.countryCode ? CustBillingAddress.countryCode : 'US'}
                                                onChange={handleBillingChange}
                                                onFocus={(event) => removeFeedbackError('billing', event.target.name)}
                                                onBlur={(event) => validateField('billing', event.target.name)}
                                                invalid={!!feedbackErrors.billing_countryCode}
                                                disabled={!billingAddressEditable || !isPersonalInfoModificationAllowed}
                                            >
                                                <option id="US" value="US">United States</option>
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.billing_countryCode}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='BillingState'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "State", "lbl_check_state")
                                                    :
                                                    "State"
                                                }
                                            </Label>
                                            <Input type='select' name='stateCode' id='BillingState' className='custom-select'
                                                value={CustBillingAddress.stateCode}
                                                onChange={handleBillingChange}
                                                onFocus={(event) => removeFeedbackError('billing', event.target.name)}
                                                onBlur={(event) => validateField('billing', event.target.name)}
                                                invalid={!!feedbackErrors.billing_stateCode}
                                                disabled={!billingAddressEditable || !isPersonalInfoModificationAllowed}
                                            >
                                                <option id="" value="">Select State</option>
                                                <option id="AL" value="AL">Alabama</option>
                                                <option id="AK" value="AK">Alaska</option>
                                                <option id="AS" value="AS">American Samoa</option>
                                                <option id="AZ" value="AZ">Arizona</option>
                                                <option id="AR" value="AR">Arkansas</option>
                                                <option id="CA" value="CA">California</option>
                                                <option id="CO" value="CO">Colorado</option>
                                                <option id="CT" value="CT">Connecticut</option>
                                                <option id="DE" value="DE">Delaware</option>
                                                <option id="DC" value="DC">District of Columbia</option>
                                                <option id="FL" value="FL">Florida</option>
                                                <option id="GA" value="GA">Georgia</option>
                                                <option id="GU" value="GU">Guam</option>
                                                <option id="HI" value="HI">Hawaii</option>
                                                <option id="ID" value="ID">Idaho</option>
                                                <option id="IL" value="IL">Illinois</option>
                                                <option id="IN" value="IN">Indiana</option>
                                                <option id="IA" value="IA">Iowa</option>
                                                <option id="KS" value="KS">Kansas</option>
                                                <option id="KY" value="KY">Kentucky</option>
                                                <option id="LA" value="LA">Louisiana</option>
                                                <option id="ME" value="ME">Maine</option>
                                                <option id="MD" value="MD">Maryland</option>
                                                <option id="MA" value="MA">Massachusetts</option>
                                                <option id="MI" value="MI">Michigan</option>
                                                <option id="MN" value="MN">Minnesota</option>
                                                <option id="MS" value="MS">Mississippi</option>
                                                <option id="MO" value="MO">Missouri</option>
                                                <option id="MT" value="MT">Montana</option>
                                                <option id="NE" value="NE">Nebraska</option>
                                                <option id="NV" value="NV">Nevada</option>
                                                <option id="NH" value="NH">New Hampshire</option>
                                                <option id="NJ" value="NJ">New Jersey</option>
                                                <option id="NM" value="NM">New Mexico</option>
                                                <option id="NY" value="NY">New York</option>
                                                <option id="NC" value="NC">North Carolina</option>
                                                <option id="ND" value="ND">North Dakota</option>
                                                <option id="MP" value="MP">North Mariana Islands</option>
                                                <option id="OH" value="OH">Ohio</option>
                                                <option id="OK" value="OK">Oklahoma</option>
                                                <option id="OR" value="OR">Oregon</option>
                                                <option id="PA" value="PA">Pennsylvania</option>
                                                <option id="PR" value="PR">Puerto Rico</option>
                                                <option id="RI" value="RI">Rhode Island</option>
                                                <option id="SC" value="SC">South Carolina</option>
                                                <option id="SD" value="SD">South Dakota</option>
                                                <option id="TN" value="TN">Tennessee</option>
                                                <option id="TX" value="TX">Texas</option>
                                                <option id="UT" value="UT">Utah</option>
                                                <option id="VT" value="VT">Vermont</option>
                                                <option id="VI" value="VI">Virgin Islands</option>
                                                <option id="VA" value="VA">Virginia</option>
                                                <option id="WA" value="WA">Washington</option>
                                                <option id="WV" value="WV">West Virginia</option>
                                                <option id="WI" value="WI">Wisconsin</option>
                                                <option id="WY" value="WY">Wyoming</option>
                                                <option id="AA" value="AA">Armed Forces AA</option>
                                                <option id="AE" value="AE">Armed Forces AE</option>
                                                <option id="AP" value="AP">Armed Forces AP</option>
                                                <option className="d-none" id="OTHER" value="OTHER">OTHER</option>
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.billing_stateCode}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='BillingCity'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "City", "lbl_check_city")
                                                    :
                                                    "City"
                                                }
                                            </Label>
                                            <Input type='text' name='city' id='BillingCity'
                                                maxLength='30'
                                                value={CustBillingAddress.city}
                                                onChange={handleBillingChange}
                                                onFocus={(event) => removeFeedbackError('billing', event.target.name)}
                                                onBlur={(event) => validateField('billing', event.target.name)}
                                                invalid={!!feedbackErrors.billing_city}
                                                autoComplete={ssACEnabled ? "BillingCity" : ""}
                                                disabled={!billingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.billing_city}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='BillingZip'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Zip", "lbl_check_zip")
                                                    :
                                                    "Zip"
                                                }
                                            </Label>
                                            <Input type='text' name='postalCode' id='postalCode' minLength='5' maxLength='10'
                                                value={CustBillingAddress.postalCode}
                                                onChange={handleBillingChange}
                                                onFocus={(event) => removeFeedbackError('billing', event.target.name)}
                                                onBlur={(event) => validateField('billing', event.target.name)}
                                                invalid={!!feedbackErrors.billing_postalCode}
                                                autoComplete={ssACEnabled ? "postalCode" : ""}
                                                disabled={!billingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.billing_postalCode}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required mb-0'>
                                            <Label for='BillingPhone'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Phone", "lbl_check_phone")
                                                    :
                                                    "Phone"
                                                }
                                            </Label>
                                            <Input type='tel' name='phone' id='BillingPhone' maxLength='10'
                                                value={CustBillingAddress.phone}
                                                onChange={handleBillingChange}
                                                onFocus={(event) => removeFeedbackError('billing', event.target.name)}
                                                onBlur={(event) => validateField('billing', event.target.name)}
                                                invalid={!!feedbackErrors.billing_phone}
                                                disabled={!billingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.billing_phone}</FormFeedback>
                                        </FormGroup>
                                    </CardBody>
                                    <hr className='my-0'></hr>

                                    {/* Shipping Address */}
                                    <CardHeader tag='h5' className='no-bg-header border-bottom-0 pt-md-6'>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Shipping Address", "lbl_myaccount_shippingaddress")
                                            :
                                            "Shipping Address"
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup className="form-group custom-control custom-checkbox" check>
                                            <Input type='checkbox' name='SameAsBillingAddress' id='SameAsBillingAddress' className='custom-control-input'
                                                onChange={() => setSameAsBillingAddress(!sameAsBillingAddress)}
                                                checked={sameAsBillingAddress}
                                                disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <Label for='SameAsBillingAddress' className='custom-control-label' check>
                                                <span className='label'>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Same as billing address", "lbl_check_sameasbillingaddress")
                                                        :
                                                        "Same as billing address"
                                                    }
                                                </span>
                                            </Label>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingFirstName'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "First Name", "lbl_check_fname")
                                                    :
                                                    "First Name"
                                                }
                                            </Label>
                                            <Input type='text' name='firstName' id='ShippingFirstName' maxLength='15'
                                                value={CustShippingAddress.firstName}
                                                onChange={handleShippingChange}
                                                onFocus={(event) => !sameAsBillingAddress && removeFeedbackError('shipping', event.target.name)}
                                                onBlur={(event) => !sameAsBillingAddress && validateField('shipping', event.target.name)}
                                                invalid={!sameAsBillingAddress && !!feedbackErrors.shipping_firstName}
                                                readOnly={sameAsBillingAddress}
                                                disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{!sameAsBillingAddress && feedbackErrors.shipping_firstName}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingLastName'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Last Name", "lbl_check_lname")
                                                    :
                                                    "Last Name"
                                                }
                                            </Label>
                                            <Input type='text' name='lastName' id='ShippingLastName' maxLength='20'
                                                value={CustShippingAddress.lastName}
                                                onChange={handleShippingChange}
                                                onFocus={(event) => !sameAsBillingAddress && removeFeedbackError('shipping', event.target.name)}
                                                onBlur={(event) => !sameAsBillingAddress && validateField('shipping', event.target.name)}
                                                invalid={!sameAsBillingAddress && !!feedbackErrors.shipping_lastName}
                                                readOnly={sameAsBillingAddress}
                                                disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{!sameAsBillingAddress && feedbackErrors.shipping_lastName}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingAddress1'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Address 1", "lbl_check_address1")
                                                    :
                                                    "Address 1"
                                                }
                                            </Label>
                                            <Dropdown isOpen={openSuggestShippingDropdown} toggle={toggleSuggestShippingDropdown}>
                                                <DropdownToggle tag="div">
                                                    <Input type='text' name='address1' id='ShippingAddress1' maxLength='35'
                                                        value={CustShippingAddress.address1 || ''}
                                                        onChange={(e) => { handleShippingChange(e); debouncedSuggestAddressDropdown(e); }}
                                                        invalid={!sameAsBillingAddress && !!feedbackErrors.shipping_address1}
                                                        autoComplete={ssACEnabled ? "ShippingAddress1" : ""}
                                                        readOnly={sameAsBillingAddress}
                                                        disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                                    />
                                                    <FormFeedback type='invalid'>{!sameAsBillingAddress && feedbackErrors.shipping_address1}</FormFeedback>
                                                </DropdownToggle>
                                                {smartySuggestionShippingDropdown.length > 0 && <DropdownMenu>
                                                    {smartySuggestionShippingDropdown?.map((item) => {
                                                        return (
                                                            <DropdownItem onClick={() => selectSmartyShippingAddress(item)}>{item.asString}</DropdownItem>
                                                        )
                                                    })}
                                                </DropdownMenu>}
                                                {isFetchingSuggestions && <Spinner className="position-absolute" style={{ right: 10, top: 10, left: 'auto' }} />}
                                            </Dropdown>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for='ShippingAddress2'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Address 2 (optional)", "lbl_check_address2optional")
                                                    :
                                                    "Address 2 (optional)"
                                                }
                                            </Label>
                                            <Input type='text' name='address2' id='ShippingAddress2' maxLength='35'
                                                value={CustShippingAddress.address2}
                                                onChange={handleShippingChange}
                                                readOnly={sameAsBillingAddress}
                                                autoComplete={ssACEnabled ? "ShippingAddress2" : ""}
                                                disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingCountry'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Country", "lbl_check_country")
                                                    :
                                                    "Country"
                                                }
                                            </Label>
                                            {sameAsBillingAddress ?
                                                <>
                                                    <Input name='countryCode' id='countryCode' type='text'
                                                        value={CustShippingAddress.countryCode === 'US' ? 'United States' : CustShippingAddress.countryCode}
                                                        readOnly={true}
                                                    ></Input>
                                                </>
                                                :
                                                <>
                                                    <Input name='countryCode' id='ShippingCountry' type='select' className='custom-select'
                                                        value={CustShippingAddress.countryCode ? CustShippingAddress.countryCode : 'US'}
                                                        onChange={handleShippingChange}
                                                        onFocus={(event) => removeFeedbackError('shipping', event.target.name)}
                                                        onBlur={(event) => validateField('shipping', event.target.name)}
                                                        invalid={!!feedbackErrors.shipping_countryCode}
                                                        disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                                    >
                                                        <option id="US" value="US">United States</option>
                                                    </Input>
                                                    <FormFeedback type='invalid'>{feedbackErrors.shipping_countryCode}</FormFeedback>
                                                </>
                                            }
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingState'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "State", "lbl_check_state")
                                                    :
                                                    "State"
                                                }
                                            </Label>
                                            {sameAsBillingAddress ?
                                                <>
                                                    <Input name='stateCode' id='ShippingState' type='text'
                                                        value={CustShippingAddress.stateCode}
                                                        readOnly={true}
                                                    ></Input>
                                                </>
                                                :
                                                <>
                                                    <Input name='stateCode' id='ShippingState' type='select' className='custom-select'
                                                        value={CustShippingAddress.stateCode}
                                                        onChange={handleShippingChange}
                                                        onFocus={(event) => removeFeedbackError('shipping', event.target.name)}
                                                        onBlur={(event) => validateField('shipping', event.target.name)}
                                                        invalid={!!feedbackErrors.shipping_stateCode}
                                                        disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                                    >
                                                        <option id="" value="">Select State</option>
                                                        <option id="AL" value="AL">Alabama</option>
                                                        <option id="AK" value="AK">Alaska</option>
                                                        <option id="AS" value="AS">American Samoa</option>
                                                        <option id="AZ" value="AZ">Arizona</option>
                                                        <option id="AR" value="AR">Arkansas</option>
                                                        <option id="CA" value="CA">California</option>
                                                        <option id="CO" value="CO">Colorado</option>
                                                        <option id="CT" value="CT">Connecticut</option>
                                                        <option id="DE" value="DE">Delaware</option>
                                                        <option id="DC" value="DC">District of Columbia</option>
                                                        <option id="FL" value="FL">Florida</option>
                                                        <option id="GA" value="GA">Georgia</option>
                                                        <option id="GU" value="GU">Guam</option>
                                                        <option id="HI" value="HI">Hawaii</option>
                                                        <option id="ID" value="ID">Idaho</option>
                                                        <option id="IL" value="IL">Illinois</option>
                                                        <option id="IN" value="IN">Indiana</option>
                                                        <option id="IA" value="IA">Iowa</option>
                                                        <option id="KS" value="KS">Kansas</option>
                                                        <option id="KY" value="KY">Kentucky</option>
                                                        <option id="LA" value="LA">Louisiana</option>
                                                        <option id="ME" value="ME">Maine</option>
                                                        <option id="MD" value="MD">Maryland</option>
                                                        <option id="MA" value="MA">Massachusetts</option>
                                                        <option id="MI" value="MI">Michigan</option>
                                                        <option id="MN" value="MN">Minnesota</option>
                                                        <option id="MS" value="MS">Mississippi</option>
                                                        <option id="MO" value="MO">Missouri</option>
                                                        <option id="MT" value="MT">Montana</option>
                                                        <option id="NE" value="NE">Nebraska</option>
                                                        <option id="NV" value="NV">Nevada</option>
                                                        <option id="NH" value="NH">New Hampshire</option>
                                                        <option id="NJ" value="NJ">New Jersey</option>
                                                        <option id="NM" value="NM">New Mexico</option>
                                                        <option id="NY" value="NY">New York</option>
                                                        <option id="NC" value="NC">North Carolina</option>
                                                        <option id="ND" value="ND">North Dakota</option>
                                                        <option id="MP" value="MP">North Mariana Islands</option>
                                                        <option id="OH" value="OH">Ohio</option>
                                                        <option id="OK" value="OK">Oklahoma</option>
                                                        <option id="OR" value="OR">Oregon</option>
                                                        <option id="PA" value="PA">Pennsylvania</option>
                                                        <option id="PR" value="PR">Puerto Rico</option>
                                                        <option id="RI" value="RI">Rhode Island</option>
                                                        <option id="SC" value="SC">South Carolina</option>
                                                        <option id="SD" value="SD">South Dakota</option>
                                                        <option id="TN" value="TN">Tennessee</option>
                                                        <option id="TX" value="TX">Texas</option>
                                                        <option id="UT" value="UT">Utah</option>
                                                        <option id="VT" value="VT">Vermont</option>
                                                        <option id="VI" value="VI">Virgin Islands</option>
                                                        <option id="VA" value="VA">Virginia</option>
                                                        <option id="WA" value="WA">Washington</option>
                                                        <option id="WV" value="WV">West Virginia</option>
                                                        <option id="WI" value="WI">Wisconsin</option>
                                                        <option id="WY" value="WY">Wyoming</option>
                                                        <option id="AA" value="AA">Armed Forces AA</option>
                                                        <option id="AE" value="AE">Armed Forces AE</option>
                                                        <option id="AP" value="AP">Armed Forces AP</option>
                                                        <option className="d-none" id="OTHER" value="OTHER">OTHER</option>
                                                    </Input>
                                                    <FormFeedback type='invalid'>{feedbackErrors.shipping_stateCode}</FormFeedback>
                                                </>
                                            }
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingCity'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "City", "lbl_check_city")
                                                    :
                                                    "City"
                                                }
                                            </Label>
                                            <Input type='text' name='city' id='ShippingCity'
                                                maxLength='30'
                                                value={CustShippingAddress.city}
                                                onChange={handleShippingChange}
                                                onFocus={(event) => !sameAsBillingAddress && removeFeedbackError('shipping', event.target.name)}
                                                onBlur={(event) => !sameAsBillingAddress && validateField('shipping', event.target.name)}
                                                invalid={!sameAsBillingAddress && !!feedbackErrors.shipping_city}
                                                readOnly={sameAsBillingAddress}
                                                autoComplete={ssACEnabled ? "ShippingCity" : ""}
                                                disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{!sameAsBillingAddress && feedbackErrors.shipping_city}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingZip'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Zip", "lbl_check_zip")
                                                    :
                                                    "Zip"
                                                }
                                            </Label>
                                            <Input type='text' name='postalCode' id='ShippingZip' minLength='5' maxLength='10'
                                                value={CustShippingAddress.postalCode}
                                                onChange={handleShippingChange}
                                                onFocus={(event) => !sameAsBillingAddress && removeFeedbackError('shipping', event.target.name)}
                                                onBlur={(event) => !sameAsBillingAddress && validateField('shipping', event.target.name)}
                                                invalid={!sameAsBillingAddress && !!feedbackErrors.shipping_postalCode}
                                                readOnly={sameAsBillingAddress}
                                                autoComplete={ssACEnabled ? "ShippingZip" : ""}
                                                disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{!sameAsBillingAddress && feedbackErrors.shipping_postalCode}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingPhone'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Phone", "lbl_check_phone")
                                                    :
                                                    "Phone"
                                                }
                                            </Label>
                                            <Input type='tel' name='phone' id='ShippingPhone' maxLength='10'
                                                value={CustShippingAddress.phone}
                                                onChange={handleShippingChange}
                                                onFocus={(event) => !sameAsBillingAddress && removeFeedbackError('shipping', event.target.name)}
                                                onBlur={(event) => !sameAsBillingAddress && validateField('shipping', event.target.name)}
                                                invalid={!sameAsBillingAddress && !!feedbackErrors.shipping_phone}
                                                readOnly={sameAsBillingAddress}
                                                disabled={!shippingAddressEditable|| !isPersonalInfoModificationAllowed}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{!sameAsBillingAddress && feedbackErrors.shipping_phone}</FormFeedback>
                                        </FormGroup>

                                        <div className='row'>
                                            {isPersonalInfoModificationAllowed &&
                                            <div className="col-12 col-md mb-4 mb-md-0">
                                                <button type="submit" name="save" className="btn btn-save btn-block btn-primary" disabled={!billingAddressEditable && !shippingAddressEditable}>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Save", "lbl_myaccount_save")
                                                        :
                                                        "Save"
                                                    }
                                                </button>
                                            </div>
                                            }
                                            <div className="col-12 col-md">
                                                <Link to={`/${getLanguageCodeFromSession()}/my-account`} className="btn btn-outline-primary bg-white w-100">
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        (isPersonalInfoModificationAllowed ? replaceLoclizationLabel(LocalizationLabelsArray, "Cancel", "lbl_myaccount_cancel") : "Back to My Account")
                                                        :
                                                        (isPersonalInfoModificationAllowed ? "Cancel" : "Back to My Account")
                                                    }
                                                </Link>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default UpdateProfile;
