import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import { checkIfStringIsEmtpy } from '../../../helpers/ValidationHelper';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { IsValidSession } from '../../../helpers/CommonHelper';


const Footer = () => {
    const dispatch = useDispatch();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [adminPanelBaseURL, setadminPanelBaseURL] = useState(Config['ENV_ADMIN_BASE_URL'] ?? Config['ADMIN_BASE_URL']);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    // const [LogoImageFromStorage, setLogoImageFromStorage] = useState(useSelector(state => state.commonReducer.websiteLogoInLocalStorage));
    const LogoImageFromStorage = useSelector(state => state.commonReducer.websiteLogoInLocalStorage);
    const loggedInUser = useSelector(state => state.userReducer.user);

    const collapsibleContent = [
        {title: 'Customer Service', langkey: 'lbl_footr_customerservice', id: 'title-custserv', links: [
            {title: 'FAQs', langkey: 'lbl_footr_faqs', url: `/${getLanguageCodeFromSession()}/faq`},
            {title: 'Order Status', langkey: 'lbl_footr_orderstatus', url: `/${getLanguageCodeFromSession()}/orders-history`},
            {title: 'Returns', langkey: 'lbl_footr_returns', url: `/${getLanguageCodeFromSession()}/returns`},
            {title: 'Modify My Order', langkey: 'lbl_footr_modifymyorder', url: `/${getLanguageCodeFromSession()}/order-modification`},
            {title: 'My Account', langkey: 'lbl_footr_myaccount', url: `/${getLanguageCodeFromSession()}/my-account`},
            {title: 'Sizing Information', langkey: 'lbl_footr_sizinginformation', url: `/${getLanguageCodeFromSession()}/sizing-information`}
        ]},
        {title: 'Company', langkey: 'lbl_footr_company', id: 'title-company', links: [
            {title: 'Home', langkey: 'lbl_footr_home', url: `/${getLanguageCodeFromSession()}/`},
            {title: 'About Us', langkey: 'lbl_footr_aboutus', url: `/${getLanguageCodeFromSession()}/about`},
            {title: 'Contact Us', langkey: 'lbl_footr_contactus', url: `/${getLanguageCodeFromSession()}/contact-us`},
            {title: 'Privacy Policy', langkey: 'lbl_footr_privacypolicy', url: `/${getLanguageCodeFromSession()}/privacy-policy`},
            {title: 'Terms & Conditions', langkey: 'lbl_footr_termsandconditions', url: `/${getLanguageCodeFromSession()}/terms`}
        ]}
    ];
    const [collapseContent, setCollapseContent] = useState({});
    const collapseFooterContent = (e) => {
        setCollapseContent({...collapseContent,[e.target.id]:!collapseContent[e.target.id]})
    };

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Footer"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])

    return (        
        <>
            <footer id="footercontent">
                <div className="container">
                    <div className="row mx-0 justify-content-between">
                        <div className="footer-signup px-0 col-12 col-md mr-md-8">
                            <div className="footer-left-column-inner">
                                <div className="w-100">
                                    <Link to={`/${getLanguageCodeFromSession()}/`} className="d-inline-block mb-4">
                                        <img src={"https://www.uniformadvantage.com/on/demandware.static/Sites-UA-Site/-/default/dw24faff8d/images/logo.svg"} className='logo-footer img-fluid' alt="logo" />
                                    </Link>
                                    <p>For over 35 years, medical apparel has been our core business. Whether creating our own scrub collections or retailing others, we obsess over the details. From product design and planning to program execution, we strive to exceed our business partners' expectations.</p>
                                </div>
                                <div className="w-100">
                                    <h3>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Get Social With Us", "lbl_footr_getsocial")
                                            :
                                            "Get Social With Us"
                                        }
                                    </h3>
                                    {/* MASR - UA Content Asset */}
                                    <ul className="social-links">
                                        <li><a className="facebook" aria-label="Facebook" href="https://facebook.com/UniformAdvantage" target="_blank"></a></li>
                                        <li><a className="instagramm" aria-label="Instagram" href="https://instagram.com/uniformadvantage" target="_blank"></a></li>
                                        <li><a className="pinterest" aria-label="Pinterest" href="https://pinterest.com/uascrubs" target="_blank"></a></li>
                                        <li><a className="tiktok" aria-label="TikTok" href="https://www.tiktok.com/@uniformadvantage?lang=en" target="_blank"></a></li>
                                    </ul>
                                </div>
                                <div className="copyright-notice d-none d-md-block">
                                    {/* MASR - UA Content Asset */}
                                    <div className="copyright">
                                        Copyright &copy; {new Date().getFullYear()} UABrands LLC. All Rights Reserved By <Link to="https://uniformadvantage.com" >UniformAdvantage</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loggedInUser.UserID && ( 
                        <div className="footer-container col-12 col-md-auto mr-llg-9 p-md-0">
                            <div className="row m-0">
                                {collapsibleContent.map((e, i) => (
                                    <div key={i} className={collapseContent[e.id] ? "footer-item col-md-auto pl-md-8 pl-xl-9 collapsible-sm active" : "footer-item col-md-auto pl-md-8 pl-xl-9 collapsible-sm"}>
                                        <h2 id={e.id} className="title" onClick={collapseFooterContent} aria-expanded={collapseContent[e.id] ? "true" : "false"}>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, e.title, e.langkey)
                                                :
                                                e.title
                                            }
                                        </h2>
                                        <ul id="collapsible-customer-service" className="menu-footer content">
                                            {e.links.map((l, i) => (
                                                <li key={i}>
                                                    <Link to={l.url}>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, l.title, l.langkey)
                                                            :
                                                            l.title
                                                        }
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            <ul className="payment-card d-none">
                                {paymentMethods?.map((item, idx) =>
                                    <li key={item.PaymentMethodId}>
                                        <Link to="#">
                                            <img src={adminPanelBaseURL + item.ImageUrl} alt="image" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                            </div>
                        </div>)}
                    </div>
                </div>
            </footer>

            <div className="container footer-secondary">
                <br/>
                {/* <div className="d-flex flex-column flex-md-row align-items-center justify-content-center my-5 my-md-6 footer-logos-container">                    
                    <a className="mx-md-7 mb-5" href="//www.uniformadvantage.com" target="_blank">
                        <img alt="Uniform Advantage" src="https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dw741caa63/images/footer/uniform-advantage.svg"/>
                    </a>
                    <a className="mx-mdm-7 mb-5" href="//www.uacorporate.com" target="_blank">
                        <img alt="Uniform Advantage Corporate Solutions" src="https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dw99fe6b8b/images/footer/uniform-advantage-corporate.svg"/>
                    </a>
                    <a className="mx-sm-7 mb-md-5" href="//www.chefuniforms.com" target="_blank">
                        <img alt="Chef Uniforms" src="https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dw0e0f9ffc/images/footer/chef-uniforms.svg"/>
                    </a>
                </div> */}
                <div className="col-12 copyright-notice d-md-none py-4">
                    {/* MASR - UA Content Asset */}
                    <div className="copyright">
                        Copyright &copy; {new Date().getFullYear()} UABrands LLC. All Rights Reserved By <Link to="https://uniformadvantage.com" >UniformAdvantage</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
