import { useEffect, useState, useRef, Fragment } from 'react';
import 'react-credit-cards-2/dist/lib/styles.scss';
import { formatCurrency } from "../../../helpers/ConversionHelper";
import { Input } from 'reactstrap';
import LoadingWrapper from '../products/LoadingWrapper.js';
import { useSelector } from 'react-redux';
import { useGetCartDataQuery } from '../../../services/cart.ts';
import { useGetGcBalanceMutation, useAddGcToCartMutation, useRemoveGCFromCartMutation } from '../../../services/cart.ts';
import rootAction from '../../../stateManagment/actions/rootAction.js';
import { useAppDispatch } from '../../../stateManagment/reduxStore.js';


export const GiftCardForm = () => {
  useGetCartDataQuery();
  const dispatch = useAppDispatch();
  const currentCart = useSelector((state) => state.cartReducer);
  const [formGC, setFormGC] = useState({});
  const [isWorkingOnGCData, setIsWorkingOnGCData] = useState(false);
  const [expandForm, setExpandForm] = useState(false);
  const GiftCardRef = useRef();
  const [gcNumber, setGCNumber] = useState('');
  const [valError, setValError] = useState('');
  const [valGc, setValGc] = useState(-1.0);
  const [gcDisplayData, setGcDisplayData] = useState([]);
  const [focused, setFocused] = useState('');
  const [getGcBalanceRtk, { isLoading: gcBalanceLoading }] = useGetGcBalanceMutation();
  const [addGcToCartRtk, { isLoading: gcAddLoading }] = useAddGcToCartMutation();
  const [removeGcFromCartRtk, { sLoading: gcRemoveLoading }] = useRemoveGCFromCartMutation();
  const [gcAdequateForCart, setGcAdequateForCart] = useState(false);

  const toggleForm = () => {
    setExpandForm(!expandForm);
  }

  const removeGcFromCart = async (pi) => {
    setValError('');
    setValGc(-1.0);

    try {
      setIsWorkingOnGCData(true);
      const gcRemoveResponse = await removeGcFromCartRtk(pi).unwrap();
      dispatch(rootAction.cartActions.setCart(gcRemoveResponse));
    } catch (err) {
      let error = {}
      error.gcNumber = err.data.message;
      setValError(error);
      GiftCardRef.current.scrollIntoView({ block: "center" });
    } finally {
      setIsWorkingOnGCData(false);
    }
  }

  const gcApply = async () => {
    setValError('');
    setValGc(-1.0);
    if (gcDisplayData && gcDisplayData.length == 4) {
      let error = {}
      error.gcNumber = "A maximum of 4 GC(s)/eGC(s) may be applied to an order. Remove one or more applied GC/eGC(s) before applying another.";
      setValError(error);
      GiftCardRef.current.scrollIntoView({ block: "center" });
      return;
    }
    if (!validateCreditCard())
      return;
    try {
      setIsWorkingOnGCData(true);
      const gcAddResponse = await addGcToCartRtk(gcNumber).unwrap();
      dispatch(rootAction.cartActions.setCart(gcAddResponse));
    } catch (err) {
      let error = {}
      error.gcNumber = err.data.message;
      setValError(error);
      GiftCardRef.current.scrollIntoView({ block: "center" });
    } finally {
      setIsWorkingOnGCData(false);
    }
  }

  const gcCheckBalance = async (e) => {
    e.preventDefault();
    setValError('');
    setValGc(-1.0);
    if (!validateCreditCard())
      return;
    try {
      setIsWorkingOnGCData(true);
      const gcBalanceResponse = await getGcBalanceRtk(gcNumber).unwrap();
      if (gcBalanceResponse != null && gcBalanceResponse.data != null) {
        let gcDetail = gcBalanceResponse.data;
        if (gcDetail.status.success != true) {
          if (gcDetail.status.errors && gcDetail.status.errors.length > 0)
            throw new Error(gcDetail.status?.errors[0]?.errormessage);
          else
            throw new Error("Unknown error while checking GC/eGC balance");
        } else {
          setValGc(gcDetail.response.giftCardBalance);
        }
      }
    } catch (err) {
      console.error(err);
      let error = {}
      error.gcNumber = err.message;
      setValError(error);
      GiftCardRef.current.scrollIntoView({ block: "center" });
    } finally {
      setIsWorkingOnGCData(false);
    }
  }

  const validateCreditCard = () => {
    let error = {}

    //Initial data validation
    if (!gcNumber || gcNumber.length <= 0) {
      error.gcNumber = "GC/eGC number cannot be empty";
      setValError(error);
      GiftCardRef.current.scrollIntoView({ block: "center" });
      return false;
    }

    if (!gcNumber || gcNumber.length != 23) {
      error.gcNumber = "GC/eGC number must be exactly 23 digits in length";
      setValError(error);
      GiftCardRef.current.scrollIntoView({ block: "center" });
      return false;
    }
    return true;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let valueSanitized;
    switch (name) {
      case 'gcNumber':
        valueSanitized = value.replace(/[^0-9]/g, '').substring(0, 23);
        setGCNumber(valueSanitized);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    var gcData = (
      currentCart?.paymentInstruments ?
        [...currentCart?.paymentInstruments] : [])
      .sort((a, b) => { return b.amount - a.amount });

    setGcDisplayData(gcData);

    if (gcData && gcData.length > 0) {
      setExpandForm(true);
      var gcAmt = 0.0;

      gcData.map(piGc => {
        gcAmt += piGc.availableAmount * 1;
      });

      setGcAdequateForCart(gcAmt >= currentCart.orderTotal);
    } else setGcAdequateForCart(false);
  }, [currentCart.paymentInstruments]);

  return (
    <LoadingWrapper isLoading={isWorkingOnGCData || gcBalanceLoading || gcAddLoading || gcRemoveLoading}>
      <Fragment>
        <label className="form-control-label label collapsible-title giftcard-title text-uppercase">
          Gift Card
        </label>&nbsp;
        <div className="gctooltip">
          <button type="button" className="btn btn-link tooltip-icon" >
            <svg className="icon icon-tooltip icon-tooltip-dims ">
              <use src="../../../assets/images/compiled/sprites.svg#tooltip"></use>
            </svg>
          </button>
          <div className="tooltiptext">
            <img src="https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwf84cae08/images/gift_card_explained.jpg" alt="GC Image" />
          </div>
        </div>
        <div className="gc-collapsible">
          <div className="giftcard-content p-3 border rounded mb-4 collapsible" id="GiftCardContainer" >
            <button className="title-header btn btn-link w-100 text-left text-decoration-none" data-toggle="collapse" data-target="#giftcardContent" onClick={toggleForm} aria-expanded={expandForm} type="button" aria-controls="giftcardContent">
              <span className="gc-label" for="giftCardNumber">
                Enter Gift Card/eGift Card Number
              </span>
              <span className="hide-header" for="giftCardNumber">
                Apply Gift Card/eGift Card
              </span>
            </button>
            <div className={expandForm ? "collapse show" : "collapse hide"} id="giftcardContent" aria-labelledby="giftcardContent">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group m-0
                                  <isif condition=${pdict.forms.giftCard && pdict.forms.giftCard.giftCardNumber.mandatory === true}>required</isif>
                                  ${pdict.forms.giftCard && pdict.forms.giftCard.giftCardNumber.htmlName}">
                    <div className="giftcard-number-wrapper mb-3" ref={GiftCardRef}>
                      <Input type='text'
                        className="form-control giftCardNumber gift-input"
                        id='gcNumber'
                        name='gcNumber'
                        placeholder='Enter Gift Card Number'
                        maxLength='23'
                        value={gcNumber}
                        onChange={handleInputChange}
                        onFocus={(e) => { setFocused(e.target.name); setValError(''); }}
                        disabled={false}
                      />
                      {valError.gcNumber &&
                        <div className="invalid-feedback d-block">{valError.gcNumber}</div>
                      }
                      {valGc && valGc > 0.0 &&
                        <div className="d-block">Card Balance = ${valGc}</div>
                      }
                    </div>
                  </div>
                </div>
                <div className="row col-lg-6 col-sm-12">
                  <div className="col-6">
                    <button className="btn btn-primary btn-block btn-secondary-size apply-button"
                      onClick={gcApply}
                      id="addGiftCert" type="button"
                      disabled={gcAdequateForCart}>
                      Apply
                    </button>
                  </div>
                  <div className="col-6">
                    <button className="btn btn-link link-underline checkbal" onClick={(e) => { e.preventDefault(); gcCheckBalance(e) }} id="checkGiftCertBalance" type="button">
                      Check Balance
                    </button>
                  </div>
                </div>
              </div>
              <div id="giftcert-content" className="mt-2 mt-md-1 text-small ${hide}">
                <div className="giftcards" data-url="${URLUtils.url('GiftCard-RemoveGiftCertificate')}">
                  {gcDisplayData?.map(pi => (
                    pi.payment_method_id === 'GIFT_CERTIFICATE' || pi.paymentMethodId === 'GIFT_CERTIFICATE' &&
                    <div>
                      <span className="font-weight-bold text-uppercase">Applied:&nbsp;</span>
                      {pi.c_giftCardNumber || pi.gcNumber} - <span className="font-weight-bold">{formatCurrency(pi.amount * 1)}</span>&nbsp;
                      <button className="js-remove-gift-card btn btn-link link-underline text-small" onClick={() => removeGcFromCart(pi.payment_instrument_id || pi.paymentInstrumentId)} type="button">Remove</button>
                    </div>
                  ))}
                </div>
              </div>
              <div id="balance-result" className="d-none text-small">
                <span></span>
              </div>
              <div id="payment-message" className={gcAdequateForCart ? "text-small" : "d-none text-small"}>
                <span>The applied Gift Card balance is sufficient for this order. Any excess amount will be refunded to your GC(s)/eGC(s)</span>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </LoadingWrapper>
  );
}
