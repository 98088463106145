import React from "react"
import ContentLoader from "react-content-loader"

const PDPLoadingSkeleton = (props) => (
  <div className="container">
    <ContentLoader
      speed={2}
      viewBox="0 0 1080 720"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="7" y="22" rx="0" ry="0" width="512" height="872" />
      <rect x="543" y="23" rx="0" ry="0" width="524" height="115" />
      <rect x="543" y="146" rx="0" ry="0" width="444" height="144" />
      <rect x="543" y="300" rx="0" ry="0" width="336" height="112" />
      <rect x="543" y="421" rx="0" ry="0" width="356" height="213" />
      <rect x="543" y="644" rx="0" ry="0" width="436" height="128" />
    </ContentLoader>
  </div>
)

export default PDPLoadingSkeleton

