import { useEffect, useState } from 'react';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import ContentPage from '../../components/layout/ContentPage';
import { useDispatch } from 'react-redux';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import rootAction from '../../../stateManagment/actions/rootAction';
import Config from '../../../helpers/Config';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { useSelector } from 'react-redux';
import Seo from '../../components/shared/Seo';
import LoadingScreen from '../../components/shared/LoadingScreen';


const FaqPage = () => {
    const dispatch = useDispatch();
    const [siteTitle, setSiteTitle] = useState(useSelector(state => state.commonReducer.siteName));
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [langCode, setLangCode] = useState('');
    const [FaqsDynamicContent, setFaqsDynamicContent] = useState('');
    const [isContentLoading,setIsContentLoading] = useState(false);

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {

            dispatch(rootAction.commonAction.setLoading(true));
            setIsContentLoading(true);

            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }

            const contentParam = {
                requestParameters: {
                    ContentID: "faqs",
                },
            };

            const contentResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CONTENT_BY_ID'], null, contentParam, headers, "POST", true);
            if (contentResponse != null && contentResponse.data != null) {
                let contentDetail = JSON.parse(contentResponse.data.data);
                await setFaqsDynamicContent(contentDetail[0].ContentValue);
            }
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["FaqPage"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }

            dispatch(rootAction.commonAction.setLoading(false));
            setIsContentLoading(false);
        }

        // call the function
        dataOperationFunc().catch(console.error);
    }, []);

    useEffect(() => {
        dispatch(rootAction.commonAction.setLoading(true));

        // Load Bootstrap
        const bootStrapScript = document.createElement('script');
        bootStrapScript.src = "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.min.js";
        document.body.appendChild(bootStrapScript);

        dispatch(rootAction.commonAction.setLoading(false));

        return () => {
            document.body.removeChild(bootStrapScript);
        };

    }, []);

    return (
        <ContentPage title={"FAQs"} contentId={"faqs"} contentFull={false}>
            <Seo title="Frequently Asked Questions (FAQ)" description="Frequently Asked Questions (FAQ)" keywords="Frequently Asked Questions, FAQ" />
            <LoadingScreen loading={isContentLoading} />

            <section className="faq-area ptb-60">

                <div className="container">
                    <div className="section-title">
                        <h2><span className="dot"></span>
                            <span id="lbl_faq_pagetitle">

                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "FAQs", "lbl_faq_pagetitle")
                                    :
                                    "FAQs"
                                }
                            </span>
                        </h2>
                    </div>

                    <div className="col-12 p-0 my-8 faq-area" dangerouslySetInnerHTML={{ __html: FaqsDynamicContent }}></div>

                </div>
            </section>

            {/* <BestFacilities /> */}

        </ContentPage>
    );

}

export default FaqPage;
