import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

const Seo = ({ title, description, keywords }) => {
    const siteName = useSelector(state => state.commonReducer.siteName);
    
    return <Helmet>
        <title>{title ? title : siteName}</title>
        <meta name="description" content={description} />   
        <meta name="keywords" content={keywords} /> 
    </Helmet>;
}

export default Seo;