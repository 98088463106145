import { Product } from "../types/product"

type ProductBits = {
    productId: string
    colorId?: string
    sizeId?: string
}

export const getSku = ({ id, colors, sizes }: Product): string => {
    const productBits = [];
    let colorId, sizeId;
    if (colors.length && sizes.length) {
        productBits.push(colorId);
        productBits.push(sizeId);
    } else if (colors.length && !sizes.length) {
        productBits.push(colorId);
    } else if (!colors.length && sizes.length) {
        productBits.push(sizeId);
    }
    return productBits.join('-');
}

export const backOrderMessage = ({ inStockDate, orderable, backOrderable, stockLevel }
        : { inStockDate: number, orderable: boolean, backOrderable: boolean, stockLevel: number }) => {
    const inStockDateObj = new Date(inStockDate);
    if (!orderable && !backOrderable) return 'This item is no longer available.';
    if (backOrderable && stockLevel === 0) return `Back ordered until ${inStockDateObj.toLocaleDateString()}`;
    if (stockLevel === 0 && !backOrderable) return 'This item is out of stock.';
    return 'In Stock';
}
