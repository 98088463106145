import React, { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';


const PbeList = ({ id, employeeList = [], selectedEmployee = '', fromCart = false }) => {
    const [filter, setFilter] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [showOptions, setShowOptions] = useState(!fromCart && window.location.pathname.indexOf('cart') === -1);

    useEffect(() => {
        setShowFilter(employeeList.length > 3);
    }, [employeeList]);

    const toggle = (target) => {
        setShowOptions(!showOptions);
        if (!showOptions)
            window.setTimeout(function () {
                document.getElementById(id).focus();
            }, 0);
    }

    const getToggleIcon = (visible) => {
      return visible ? <i className='fa fa-chevron-up' /> : <i className='fa fa-chevron-down' />;
    };

    const isVisible = () => {
        if (employeeList?.length === 0)
            return false; // nothing to show

        if (selectedEmployee) { // if selectedEmployee is the only one, dont show
            var cnt = employeeList?.filter(emp => {
                return emp.Name !== selectedEmployee;
            }).length;

            if (showFilter !== cnt > 3)
                setShowFilter(cnt > 3);

            if (cnt === 0)
                return false;
        }

        return true;
    };

    const isOptionVisible = (text) => {
        return !filter || text?.toLowerCase().indexOf(filter?.toLowerCase()) > -1;
    };

    return (
        <div className={`pbeSelectionWrap ${!isVisible() ? 'hidden' : '' }`}>
            <div className='pbeSelectionToggle py-2' onClick={() => toggle()}>
                <Label className='pbe-select-title m-0'>
                    { !showOptions ? "Click to Show More" : "Click to Hide List" }
                </Label>
                {getToggleIcon(showOptions)}
            </div>
            <div className={`pbeSelection pt-0 pb-2 ${!showOptions ? 'hidden' : ''}`}>
                <div className={`custom-input-wrapper mb-1 ${!showFilter ? 'hidden' : ''}`}>
                    <Input type='text' id={id} className='pbe-filter px-2' value={filter} placeholder='FILTER BY NAME' onChange={(e) => setFilter(e.target.value)}></Input>
                </div>
                <div className={`list ${showFilter ? 'scroll' : ''}`}>
                {employeeList.filter((item) => { return item.Name !== selectedEmployee }).map((item) => {
                    return (
                        <div className={`pbe-line clearfix py-1 ${isOptionVisible(item.Name) ? '' : 'hidden'}`}>
                            <p>{item.Name.split(',').join(' ')}</p>
                            <div className='size-attribute-title label'>
                                Color
                                :&nbsp;<span className="spacer-right">{item.ColorLongName}</span>
                                Size
                                :&nbsp;<span className="spacer-right">{item.Size}</span>
                                Qty
                                :&nbsp;<span>{item.Qty}</span>
                            </div>
                        </div>
                    )
                })}
                </div>
                <hr className='mt-3 mb-0' />
            </div>
        </div>
    );

}


export default PbeList;
