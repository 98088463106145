import { useEffect, useState } from "react";
import { useValidateAddressQuery } from "../../../services/checkout";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { AddressValidateSummary } from "./AddressSummary";

export const AddressSuggestModal = ({ isOpen, toggle, address, setAddress, setAddressIsValid, smartySuggestion }) => {
    const { isFetching } = useValidateAddressQuery(isOpen ? address : skipToken);
    const [acceptedAddress, setAcceptedAddress] = useState(smartySuggestion);

    return (
        <Modal isOpen={isOpen && !isFetching} toggle={toggle} className='address-validate-modal'>
            <ModalHeader toggle={toggle}>Select Address</ModalHeader>
            <ModalBody className='row'>
                <FormGroup className='col custom-control custom-radio'>
                    <Input id='suggestedAddress' className="custom-control-input" type='radio' checked name='address' onChange={() => setAcceptedAddress(smartySuggestion)} />
                    <Label for='suggestedAddress' className="custom-control-label" >
                        <AddressValidateSummary address={smartySuggestion} title='Use suggested address' noEdit={true} />
                    </Label>
                </FormGroup>
                <FormGroup className='col custom-control custom-radio'>
                    <Input id='currentAddress' className="custom-control-input" type='radio' name='address' onChange={() => setAcceptedAddress(address)} />
                    <Label for='currentAddress' className="custom-control-label">
                        <AddressValidateSummary address={address} title='Use entered address' noEdit={true} />
                    </Label>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={() => {
                    setAddress(acceptedAddress); setAddressIsValid(true)
                }}>
                    Select
                </Button>
                <Button color='primary' outline={true} onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}