import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { blur } from '../../../utils/blur';
import LoadingWrapper from '../../components/products/LoadingWrapper';

const CollapsibleSections = ({ feature, fabric, fitSize, howToMeasureContent, isLegacySizeChart, isUAExclusive, colorMatchHelp }) => {
  const [toggleReadMore, setToggleReadMore] = useState(false);

  const [section1Visible, setSection1Visible] = useState(true);
  const [section2Visible, setSection2Visible] = useState(false);
  const [section3Visible, setSection3Visible] = useState(false);

  const [legacySizeChartURL, setLegacySizeChartURL] = useState(false);
  const [legacySizeChartURLOpen, setLegacySizeChartURLOpen] = useState(false);
  const [legacySizeChartLoading, setLegacySizeChartLoading] = useState(false);

  const toggle = () => {
    setLegacySizeChartURLOpen(!legacySizeChartURLOpen);
  };

  const toggleSection1 = () => {
    setSection1Visible(!section1Visible);
  };

  const toggleSection2 = () => {
    setSection2Visible(!section2Visible);
  };

  const toggleSection3 = () => {
    setSection3Visible(!section3Visible);
  };

  const getToggleIcon = (visible) => {
    return visible ? <i className='fa fa-chevron-up' /> : <i className='fa fa-chevron-down' />;
  };

  useEffect(() => {
    if (legacySizeChartURLOpen) {
      setLegacySizeChartLoading(true);
    }
    return () => {
      setLegacySizeChartLoading(false);
    }
  }, [legacySizeChartURLOpen]);

  return (
    <div className="accordion-cards mt-7">
      <Card>
        <CardHeader color="link" onClick={toggleSection1}>
          Features {getToggleIcon(section1Visible)}
        </CardHeader>
        <Collapse isOpen={section1Visible}>
          <CardBody>
            <p> {isUAExclusive ?   <b>ONLY &#64;UA&#174; </b> : "" } {feature.description}</p>           
            <div style={{ display: feature.list != "<ul><li></li></ul>" ? "block" : "none" }} dangerouslySetInnerHTML={{ __html: feature.list }} />
            
            <div style={{ display: colorMatchHelp ? "block" : "none" }} >
            <div>
              <b>Color Match</b>               
            </div>
            <div>
            {colorMatchHelp}
            </div>
            </div>

          </CardBody>
        </Collapse>
      </Card>
      {fabric?.description && fabric?.description?.length > 0 && (
        <Card>
          <CardHeader color="link" onClick={toggleSection2}>
            Fabric {getToggleIcon(section2Visible)}
          </CardHeader>
          <Collapse isOpen={section2Visible}>
            <CardBody>
              <p>{fabric.description}</p>
              <div style={{ display: fabric.list != "<ul><li></li></ul>" ? "block" : "none" }} dangerouslySetInnerHTML={{ __html: fabric.list }} />
            </CardBody>
          </Collapse>
        </Card>
      )}
      <Card>
        <CardHeader color="link" onClick={toggleSection3}>
          Fit & Size {getToggleIcon(section3Visible)}
        </CardHeader>
        <Collapse isOpen={section3Visible}>
          <CardBody>

            {isLegacySizeChart ? <a href={fitSize} target='_blank' onClick={(e) => { e.preventDefault(); setLegacySizeChartURL(fitSize); setLegacySizeChartURLOpen(true); }} > Size Chart  </a> : <div dangerouslySetInnerHTML={{ __html: fitSize != 'undefined' && fitSize.length > 0 ? fitSize : "No size chart info available." }} />}
            <div className={!toggleReadMore ? 'how-to-meassure-content mt-4' : 'how-to-meassure-content expanded mt-4'} dangerouslySetInnerHTML={{ __html: howToMeasureContent }} />
            {howToMeasureContent && howToMeasureContent.length > 0 && (
              <Button className='link-underline' color='link' onClick={(e) => blur(e, setToggleReadMore(!toggleReadMore))}>
                {toggleReadMore ? 'Read less' : 'Read more'}
              </Button>
            )}

          </CardBody>
        </Collapse>
      </Card>

      <Modal isOpen={legacySizeChartURLOpen} toggle={toggle} className='fullscreen-modal' >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <LoadingWrapper isLoading={legacySizeChartLoading}>
            <iframe src={legacySizeChartURL} frameborder="0"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="600px"
              onLoad={() => setLegacySizeChartLoading(false)}
            ></iframe>
          </LoadingWrapper>
        </ModalBody>
        {/* <ModalFooter>
            <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter> */}
      </Modal>

    </div >
  );
};

export default CollapsibleSections;
