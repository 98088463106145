import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Config from '../../../helpers/Config.js';

export const Qualtrics = ({ open, data, toggle }) => {
    const url = [
        Config.QUALTRICS_URL,
        `Site Name=${window.location.host}`,
        `Customer Number=${localStorage.getItem("BECustNo")}`,
        `Customer Name=${data.name}`,
        `Customer Address=${data.address}`,
        `Customer City=${data.city}`,
        `Customer State=${data.state}`,
        `Customer Zip=${data.zip}`,
        `Customer Email=${data.email}`,
        `Customer Phone=${data.phone}`,
        `Order Number=${data.orderNo}`
    ].join('&');

    return (
        <Modal isOpen={open} centered={true} className='modal-qualtrics'>
            <ModalHeader toggle={toggle} />
            <ModalBody>
                <iframe name="QSFrame" id="QSFrame" src={url}></iframe>
            </ModalBody>
        </Modal>
    );
}
