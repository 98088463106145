import React, { useEffect, useState, useContext, Fragment } from 'react';
import { convertDateToDifferentFormats, formatCurrency } from '../../../helpers/ConversionHelper';
import { Link } from 'react-router-dom';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import { PAYMENT_INSTRUMENTS } from '../../../helpers/Constants';
import { ToggleContext } from '../../../ToggleProvider';

const OrderRow = ({ order }) => {
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const [localizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const getOrderRow = async () => {
        //-- Get website localization data
        let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["OrdersHistory"], null);
        if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
            setLocalizationLabelsArray(arryRespLocalization);
        }
    };

    useEffect(() => {
        getOrderRow().catch(console.error);
    }, []);
    return (
        <>
            <div className="card border-0 card--has-bg-color p-0 mb-5">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-12 col-md order-history-divisor pb-5 pb-md-0">
                            <div className="row">
                                {/* Order number */}
                                <div id="lbl_ordrhis_orderno" className="col-5 col-lg-4 order-history-labels font-weight-bold">{replaceLoclizationLabel(localizationLabelsArray, "Order Number:", "lbl_ordrhis_orderno")}</div>
                                <div className="col-7 col-lg">{order.OrderNumber}</div>
                                <div className="w-100"></div>
                                {/* Order date */}
                                <div id="lbl_ordrhis_orderdate" className="col-5 col-lg-4 mb-4 order-history-labels font-weight-bold">{replaceLoclizationLabel(localizationLabelsArray, "Order Date:", "lbl_ordrhis_orderdate")}</div>
                                <div className="col-7 col-lg">{new Date(order.OrderDate).toLocaleDateString()}</div>
                                <div className="w-100"></div>
                                {/* Shipped To */}
                                <div id="lbl_ordrhis_shippedto" className="col-5 col-lg-4 order-history-labels font-weight-bold">{replaceLoclizationLabel(localizationLabelsArray, "Shipped To:", "lbl_ordrhis_shippedto")}</div>
                                <div data-private="lipsum" className="col-7 col-lg">{order.ShippedTo}</div>
                                <div className="w-100"></div>
                                {/* Payment */}
                                {order.PaymentInstruments?.length > 0 &&
                                    <div id="lbl_ordrhis_payment" className="col-5 col-lg-4 order-history-labels font-weight-bold">{replaceLoclizationLabel(localizationLabelsArray, "Payment:", "lbl_ordrhis_payment")}</div>}
                                {order.PaymentInstruments?.filter(pi => pi.Amount > 0).map(pi => {
                                    return (
                                        <div key={pi.PaymentMethodId} className='mb-2'>
                                            <div className="col-7 col-lg">{isToggled ? "Allotment" : PAYMENT_INSTRUMENTS[pi.PaymentMethodId]}</div>
                                            {pi.PaymentMethodId === 'UAB_PAYROLL_DEDUCTION' &&
                                                <div className="col-7 col-lg">Max Deductions: {pi.PDMaxDeductions}</div>
                                            }
                                            {!isToggled&&<div className="col-7 col-lg">Amount: {formatCurrency(pi.Amount)}</div>}
                                        </div>
                                    )
                                })}
                                <div className="w-100"></div>
                                {/* Order status */}
                                <div id="lbl_ordrhis_orderstatus" className="col-5 col-lg-4 order-history-labels font-weight-bold">{replaceLoclizationLabel(localizationLabelsArray, "Order Status:", "lbl_ordrhis_orderstatus")}</div>
                                <div className="col-7 col-lg">{order.OrderStatus}</div>
                                <div className="w-100"></div>
                                {/* Total items */}
                                <div id="lbl_ordrhis_totalitem" className="col-5 col-lg-4 order-history-labels font-weight-bold">{replaceLoclizationLabel(localizationLabelsArray, "Total Items:", "lbl_ordrhis_totalitem")}</div>
                                <div className="col-7 col-lg">{order.ItemCount}</div>
                                <div className="w-100"></div>
                                {/* Order total */}
                                {!isToggled&&<Fragment>
                                <div id="lbl_ordrhis_total" className="col-5 col-lg-4 order-history-labels font-weight-bold">{replaceLoclizationLabel(localizationLabelsArray, "Order Total:", "lbl_ordrhis_total")}</div>                                
                                <div className="col-7 col-lg">{formatCurrency(order.OrderTotal)}</div>
                                </Fragment>}
                            </div>
                        </div>

                        <div className="col-12 col-md-auto text-center mt-3 mt-lg-0 pl-lg-5">
                            <Link to={`/${getLanguageCodeFromSession()}/order-detail/${order.OrderNumber}`} className="link-underline" id="lbl_ordrhis_detaillink">
                                {replaceLoclizationLabel(localizationLabelsArray, "Details", "lbl_ordrhis_detaillink")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default OrderRow;