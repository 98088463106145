import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';

const LoadingScreen = ({ loading, detailText = ''}) => {
    const loadingState = useSelector(state => state.commonReducer.loading);
    return (
        <div style={{
            flexDirection: 'column',
            flexGrow: 1,
            flexShrink: 0,
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 20000,
            top: 0,
            backgroundColor: 'white',
            opacity: '60%',
            justifyContent: 'center',
            alignItems: 'center',
            display: loading || loadingState ? 'flex' : 'none',
        }}>
            <Spinner color='success' />
            {detailText!==''?<><div>{detailText}</div></>:<></>}
        </div>
    )
}

export default LoadingScreen;
