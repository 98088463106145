import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './features/userSlice';
import commonReducer from './features/commonSlice';
import plpReducer from './features/plpSlice';
import pdpReducer from './features/pdpSlice.ts';
import cartReducer from './features/cartSlice.ts';
import orderReducer from './features/orderSlice.ts';
import checkoutReducer from './features/checkoutSlice.ts';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { useDispatch } from 'react-redux';
import { api } from '../services/api.ts';
import thunk from 'redux-thunk';

const rootPersisConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['userReducer', 'commonReducer', 'checkoutReducer'],
  stateReconciler: autoMergeLevel2,
  // preloadedState: loadState(),
}

const checkoutPersisConfig = {
  key: 'checkout',
  storage: storage,
  blacklist: ['creditCard', 'isInOrderReview', 'paymentMethod', 'paymentMethods'],
  stateReconciler: autoMergeLevel2,
  // preloadedState: loadState(),
}

const rootReducer = combineReducers({
  userReducer,
  commonReducer,
  cartReducer,
  plpReducer,
  pdpReducer,
  checkout: persistReducer(checkoutPersisConfig, checkoutReducer),
  orderReducer,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(rootPersisConfig, rootReducer)

// Redux: Store
const reduxStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk, api.middleware),
});
// initMessageListener(reduxStore);

// Middleware: Redux Persist Persister
let persistor = persistStore(reduxStore);
// Exports
export const useAppDispatch = () => useDispatch();
export { reduxStore, persistor };
