import { Route, createRoutesFromElements, createBrowserRouter, Navigate } from "react-router-dom";
import ContactUs from "./web/views/contactUs/Index";
import Home from "./web/views/home/Index";
import AllProducts from "./web/views/products/AllProducts";
import ProductDetail from "./web/views/products/ProductDetail";

import 'react-toastify/dist/ReactToastify.css';
import Login from "./web/views/login/Login";
import Signup from "./web/views/signup/Signup";
import Checkout from "./web/views/checkout/Checkout";
import Cart from "./web/views/checkout/Cart";
import OrderReview from "./web/views/checkout/OrderReview";
import OrderConfirmation from "./web/views/orderconfirmation/OrderConfirmation";
import About from "./web/views/about/About";
import FaqPage from "./web/views/faq/FaqPage";
import UpdateProfile from "./web/views/signup/UpdateProfile";
import BecomeVendor from "./web/views/signup/BecomeVendor";
import Campaign from "./web/views/campaign/Campaign";
import OrderDetail from "./web/views/orders/OrderDetail";
import OrdersHistory from "./web/views/orders/OrdersHistory";
import ResetPassword from "./web/views/login/ResetPassword";
import Refresh from "./web/views/common/Refresh";
import ProtectedRoutes from "./ProtectedRoutes";
import NotFound from "./web/views/common/NotFound";
import ThreeDView from "./web/views/3dview/3dview";
import ChangePassword from "./web/views/login/ChangePassword";
import Sizing from "./web/views/sizing/sizing";
import Policy from "./web/views/policy/policy";
import MyAccount from "./web/views/myaccount/myaccount";
import Returns from "./web/views/returns/returns";
import OrderModification from "./web/views/ordermodification/ordermodification";
import Terms from "./web/views/terms/terms";
import EditProfile from "./web/views/myaccount/EditProfile";
import RootLayout from "./web/components/layout/RootLayout";

export const routes = createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
        {/*The catch all*/}
        <Route path='*' element={<NotFound />} />
        {/* Protected routes starts here*/}
        {/* Routes with language code starts here */}
        <Route path=":langCode?/" element={<ProtectedRoutes><Home /></ProtectedRoutes>} />
        <Route path=":langCode?/all-products/:parent_category_id/:category_id/:page?" element={<ProtectedRoutes><AllProducts /></ProtectedRoutes>} />
        <Route path=":langCode?/product-detail/:product_id/:category_id/:product_name" default element={<ProtectedRoutes><ProductDetail /></ProtectedRoutes>} />
        <Route path=":langCode?/contact-us" element={<ProtectedRoutes><ContactUs /></ProtectedRoutes>} />
        <Route path=":langCode?/contact-us.html" element={<Navigate to='/contact-us' replace />} />
        <Route path=":langCode?/checkout" element={<ProtectedRoutes><Checkout /></ProtectedRoutes>} />
        <Route path=":langCode?/order-review" element={<ProtectedRoutes><OrderReview /></ProtectedRoutes>} />
        <Route path=":langCode?/order-confirmation" element={<ProtectedRoutes><OrderConfirmation /></ProtectedRoutes>} />
        <Route path=":langCode?/cart" element={<ProtectedRoutes><Cart /></ProtectedRoutes>} />
        <Route path=":langCode?/about" element={<ProtectedRoutes><About /></ProtectedRoutes>} />
        <Route path=":langCode?/faq" element={<ProtectedRoutes><FaqPage /></ProtectedRoutes>} />
        <Route path=":langCode?/update-profile" element={<ProtectedRoutes><UpdateProfile /></ProtectedRoutes>} />
        <Route path=":langCode?/campaign/:campaign_id/:campaign_main_title" default element={<ProtectedRoutes><Campaign /></ProtectedRoutes>} />
        <Route path=":langCode?/order-detail/:order_number" element={<ProtectedRoutes><OrderDetail /></ProtectedRoutes>} />
        <Route path=":langCode?/orders-history" element={<ProtectedRoutes><OrdersHistory /></ProtectedRoutes>} />
        <Route path=":langCode?/refresh" element={<ProtectedRoutes><Refresh /></ProtectedRoutes>} />
        <Route path=":langCode?/become-seller" element={<ProtectedRoutes><BecomeVendor /></ProtectedRoutes>} />
        <Route path=":langCode?/3dview" element={<ProtectedRoutes><ThreeDView /></ProtectedRoutes>} />
        <Route path=":langCode?/change-password" element={<ProtectedRoutes><ChangePassword /></ProtectedRoutes>} />
        <Route path=":langCode?/sizing-information" element={<ProtectedRoutes><Sizing /></ProtectedRoutes>} />
        <Route path=":langCode?/privacy-policy" element={<ProtectedRoutes><Policy /></ProtectedRoutes>} />
        <Route path=":langCode?/privacy-policy.html" element={<Navigate to='/privacy-policy' replace />} />
        <Route path=":langCode?/my-account" element={<ProtectedRoutes><MyAccount /></ProtectedRoutes>} />
        <Route path=":langCode?/edit-profile" element={<ProtectedRoutes><EditProfile /></ProtectedRoutes>} />
        <Route path=":langCode?/returns" element={<ProtectedRoutes><Returns /></ProtectedRoutes>} />
        <Route path=":langCode?/order-modification" element={<ProtectedRoutes><OrderModification /></ProtectedRoutes>} />
        <Route path=":langCode?/terms" element={<ProtectedRoutes><Terms /></ProtectedRoutes>} />
        {/* Routes with language code ends here */}
        {/* Protected routes ends here*/}

        {/* Non protected routes starts here*/}
        {/* Routes with language code starts here */}
        <Route path=":langCode?/login" element={<Login />} />
        <Route path=":langCode?/signup" element={<Signup />} />
        <Route path=":langCode?/reset-password" element={<ResetPassword />} />
        {/* Routes with language code ends here */}
        {/* Non protected routes ends here*/}
    </Route>
)

export const routerConfig = createBrowserRouter(
    routes
);
