import React, { } from 'react';
import OrderRow from '../../components/orderHistory/OrderRow';

export default function OrderList(props) {
    return (
        <div className="col-12 account-content-area order-list-container">
            {
                props.orders.map((order, i) => {
                    return (<OrderRow key={order.OrderNumber} order={order} />);
                })
            }
        </div>
    );
}