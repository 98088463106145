import ContentLoader from "react-content-loader";
import SiteBreadcrumb from "../layout/SiteBreadcrumb";

const OrderDetailsLoader = props => (
  <>
    <SiteBreadcrumb title="Order Detail" />
    <section className='order-details-page d-flex my-4 my-md-8' id='order-details'>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="order-details-title mb-0 mb-md-2">
              Order Details
            </h1>
          </div>
          <ContentLoader
            viewBox="0 0 700 300"
            backgroundColor="#f5f5f5"
            foregroundColor="#dbdbdb"
            {...props}
          >

            <rect x="0" y="42" rx="16" ry="16" width="450" height="216" />
            <rect x="500" y="41" rx="3" ry="3" width="200" height="210" />
          </ContentLoader>
        </div>
      </div>
    </section>
  </>
)

export default OrderDetailsLoader;
