import { useSelector } from 'react-redux';
import Sprites from '../../../assets/images/sprites.svg';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';

const resultLayouts = [
    'row-cols-md-5',
    'row-cols-md-4',
    'row-cols-md-3',
];

const resultMobileLayouts = [
    'row-cols-1',
    'row-cols-2'
];

const ProductsFilterOptions = ({refinements}) => {
    const dispatch = useAppDispatch();
    const { resultsLayout, resultsMobileLayout, totalRecords } = useSelector(state => state.plpReducer);

    const handleGrid = (e, layout) => {
        dispatch(rootAction.plpActions.setResultsLayout(layout));
    };
    const handleMobileGrid = (e, layout) => {
        dispatch(rootAction.plpActions.setResultsMobileLayout(layout));
    };

    return (
        <div className="products-filter-options d-flex align-items-center justify-content-between mb-md-5">
            {totalRecords > 0 && <div className="result-count d-flex align-items-center justify-content-between">
                <span className='filterby'>Filter by</span>
                <span className='items-count'>{totalRecords > 1 ? `${totalRecords} items` : `${totalRecords} item`}</span>
            </div>}
            <div className="result-sort d-none d-md-flex align-items-center justify-content-end ml-auto">
                {/* <div className="form-group d-flex align-items-center m-0">
                    <label className="flex-shrink-0 mb-0 mr-2">Sort By</label>
                    <select className="custom-select plp-sort-select" onChange={(e) => dispatch(rootAction.plpActions.setSortByFilter(e.target.value))}>
                        <option value="">Featured</option>
                        <option value="Price ASC">Price Ascending</option>
                        <option value="Price DESC">Price Descending</option>
                        <option value="Date DESC">Date Ascending</option>
                        <option value="Date ASC">Date Descending</option>
                    </select>
                </div> */}
                {resultLayouts.map((item) => {
                    let colNum = item.charAt(item.length - 1);
                    return (
                        <button key={item} className={`tiles-grid-style btn btn-link d-flex ${item === resultsLayout && 'active'}`} onClick={(e) => handleGrid(e, item)}>
                            <svg className={`icon icon-${colNum}col icon-${colNum}col-dims`}>
                                <use href={`${Sprites}#${colNum}col`}></use>
                            </svg>
                        </button>
                    );
                })}
            </div>
            <div className="d-flex d-md-none align-items-center tiles-grid-selector-mobile">
                {resultMobileLayouts.map((item, idx) => {
                    return (
                        // <div key={idx} className="spacer"></div>
                        <button key={idx} className={`tiles-grid-style btn-${++idx}col link-underline btn-link align-items-center js-grid-toggle-mobile ${item === resultsMobileLayout && 'active'}`} onClick={(e) => handleMobileGrid(e, item)}></button>
                    );
                })}
            </div>
        </div>
    );
}

export default ProductsFilterOptions;
