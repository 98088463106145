import React, { Component } from 'react';
import SiteBreadcrumb from './SiteBreadcrumb';
import { SiteLeftSidebarNav } from '../shared/SiteLeftSidebarNav';

class ContentPage extends Component {
    render() {
        return (
            <>
                {this.props.title !== "Home" ?
                    <SiteBreadcrumb title={this.props.title} />
                    :
                    <></>
                }
                <div id={this.props.contentId} className="container content-page">
                    <div className="row">
                        {!this.props.contentFull ?
                            <SiteLeftSidebarNav title={"Left Navigation"} />
                            :
                            <></>
                        }
                        <main className={this.props.contentFull ? "col-12" : "col-12 col-md-9"}>
                            {/* Content Goes Here */}
                            {this.props.children}
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

export default ContentPage;
