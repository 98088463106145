import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, CardHeader, CardTitle, CardSubtitle, CardBody, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap'
import NewCustomerCard from './NewCustomerCard';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import rootAction from '../../../stateManagment/actions/rootAction';
import Config from '../../../helpers/Config';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import { validateAnyFormField, validateEmail } from '../../../helpers/ValidationHelper';
import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import Seo from '../../components/shared/Seo';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { useResetPasswordMutation, useSendResetOtpMutation } from '../../../services/auth';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [searchParams] = useSearchParams();
    const qsParsed = {
        resetSessionId: searchParams.get('resetSessionId'),
        email:searchParams.get('email')
    }

    const [displayRecoverEmailForm, setdisplayRecoverEmailForm] = useState(true);
    const [displayOTPForm, setdisplayOTPForm] = useState(false);

    const [Email, setEmail] = useState('');
    const [Otp, setOtp] = useState('');
    const [TargetEmail, setTargetEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [showErrorResponse, setShowErrorResponse] = useState(false);
    const [showSuccessResponse, setShowSuccessResponse] = useState(false);
    const [ErrorResponse, setErrorResponse] = useState([]);
    const [SuccessResponse, setSuccessResponse] = useState('');
    const SignupOption = useSelector(state => state.commonReducer.selfRegister);
    const LoginSystem = useSelector(state => state.commonReducer.loginSystem);
    const [formErrors, setFormErrors] = useState({});
    const [isContentLoading,setIsContentLoading] = useState(false);
    const [newPass, setnewPassToggle] = useState(true);
    const [confirmPass, setconfirmPassToggle] = useState(true);
    const [resetPassword, { isLoading: isResetLoading }] = useResetPasswordMutation();
    const [sendResetOtp, { isLoading: isSendLoading }] = useSendResetOtpMutation();

    const removeFormError = (e) => {
        delete formErrors[e];
        setFormErrors({ ...formErrors });
    }

    const submitEmailSendOTPForm = async (event) => {
        try {

            event.preventDefault();

            //Clean previous messages
            setErrorResponse([]);
            setShowErrorResponse(false);
            setShowSuccessResponse(false);
            setSuccessResponse("");

            let isValid = false;
            let errors = {};

            //--validation for email
            if (!Email || Email === '') errors.Email = 'Please enter your Email'
            else if (Email && !validateEmail(Email)) errors.Email = 'Please enter a valid Email address'

            setFormErrors(errors);

            if (Object.keys(errors).length > 0) {
                isValid = false;
                return false;
            } else {
                isValid = true;
            }

            if (isValid) {
                sendResetOtp({
                    CustEmailID: Email,
                    DomainLang: window.location.origin
                })
                .unwrap()
                .then((resp) => {
                    if (resp.fault) {
                        setErrorResponse([resp.fault.message]);
                        setShowErrorResponse(true);
                        return false;
                    } else {
                        setShowSuccessResponse(true);
                        setSuccessResponse("Password reset initiated successfully. An email has been sent to the entered email address with a password reset link.");
                    }
                })
                .catch((error) => {
                    setErrorResponse(["An error occurred resetting your password."]);
                    setShowErrorResponse(true);
                    return false;
                });
            }
        }
        catch (err) {
            setShowErrorResponse(true);
            setShowSuccessResponse(false);
            setErrorResponse(["An error occured. Please try again!"]);

            return false;
        }
    }

    const validateForm = async (event) => {
        event.preventDefault();

        let isValid = true;
        setShowErrorResponse(false);
        setShowSuccessResponse(false);

        try {
            //--validation for email
            if (!validateAnyFormField('TargetEmail', TargetEmail, 'email', null, 200, true))
                isValid = false;

            if (!validateAnyFormField('OTP', Otp, 'text', 5, 215, true))
                isValid = false;

            if (!validateAnyFormField('Password', Password, 'password', 8, 20, true)) {
                isValid = false;
                formErrors.password = 'Please enter a valid password. Length between 8 and 20 characters';
            }

            if (!validateAnyFormField('ConfirmPassword', ConfirmPassword, 'password', 8, 20, true)) {
                isValid = false;
                formErrors.ConfirmPassword = 'Please enter a valid password that matches.';
            }

            if (ConfirmPassword && Password != ConfirmPassword) {
                isValid = false;
                formErrors.ConfirmPassword = "The Password and the Confirm Password fields must be identical.";
            }

            if (!isValid) {
                setFormErrors(formErrors);
                setErrorResponse(Object.keys(formErrors).map((key) => { return formErrors[key] }));
                setShowErrorResponse(true);
                return false;
            }

            // submit
            resetPassword({
                Email: TargetEmail,
                Otp: Otp,
                Password: Password,
                ConfirmPassword: ConfirmPassword,
            })
            .unwrap()
            .then((resp) => {
                console.log('success', resp);
                if (resp.fault) {
                    console.log('fault', resp.fault);
                    setErrorResponse([resp.fault.message]);
                    setShowErrorResponse(true);
                    return false;
                } else {
                    setShowSuccessResponse(true);
                    setSuccessResponse("Password reset successfully. You will be redirected shortly to the login page. Please login with your new password. If you are not redirected click on the 'Login' link below.");
                    setTimeout(() => {
                        navigate('/' + getLanguageCodeFromSession() + '/login');
                    }, 6000);
                }
            })
            .catch((error) => {
                setErrorResponse(["An error occurred resetting your password."]);
                setShowErrorResponse(true);
                return false;
            });
        }
        catch (err) {
            setErrorResponse(["An error occurred resetting your password."]);
            setShowErrorResponse(true);
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (qsParsed && qsParsed.resetSessionId && qsParsed.resetSessionId.length > 0) {
            //Show the second step screen
            setOtp(qsParsed.resetSessionId);
            setTargetEmail(qsParsed.email);
            setdisplayRecoverEmailForm(false);
            setdisplayOTPForm(true);
        } else {
            //show the first step screen
            setdisplayRecoverEmailForm(true);
            setdisplayOTPForm(false);
        }
    }, [qsParsed])

    return (
        <>
            <LoadingScreen loading={isResetLoading || isSendLoading} />
            <Seo title="Reset Password" description="Reset Password" keywords="Reset Password, Change Password" />

            {/* <SiteBreadcrumb title="Reset Password" /> */}

            <section className="login-area pt-6 pt-md-8 pb-9">
                <div className="container-fluid">
                    <h2 className='account-page-title text-center mb-5'>
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "Account Login", "lbl_login_resetpassword")
                            :
                            "Reset Password"
                        }
                    </h2>
                    <div className='row'>
                        <div className='col-12 col-md-6 mx-auto'>
                            <Card color="light" className="border-0 card--has-bg-color h-100">
                                <CardHeader className='border-0 card-header--no-divisor'>
                                    <CardTitle tag="h2">
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Returning Customers", "lbl_login_title")
                                            :
                                            "Returning Customers"
                                        }
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                {displayRecoverEmailForm === true ?
                                    <Form className="login-form d-flex flex-column" id="password_recovery_form" onSubmit={submitEmailSendOTPForm} noValidate>
                                        {showErrorResponse &&
                                            <Alert color="danger">
                                                {ErrorResponse.length > 1 ? 
                                                <ul>
                                                    {ErrorResponse.map( (mess) =>
                                                    {
                                                        <li>{mess}</li>
                                                    })}
                                                </ul>
                                                :
                                                <>{ErrorResponse[0]}</>
                                                }
                                            </Alert>}
                                        {showSuccessResponse &&
                                            <Alert color="success">
                                                {SuccessResponse}
                                            </Alert>}
                                        
                                        <FormGroup className='required'>
                                            <Label>Email</Label>
                                            <Input type='email'  name='Email' id='Email' value={Email}
                                                placeholder='Enter your email'
                                                onChange={(e) => setEmail(e.target.value)}
                                                onFocus={(e) => removeFormError(e.target.id)}
                                                invalid={!!formErrors.Email}
                                            >
                                            </Input>
                                            <FormFeedback>{formErrors.Email}</FormFeedback>
                                        </FormGroup>

                                        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mt-auto">
                                            <Button type='submit' color='primary' className='mt-auto w-100' id="lbl_login_loginbtn">
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Reset Password", "lbl_login_resetpassword")
                                                    :
                                                    "Reset Password"
                                                }
                                            </Button>
                                            <Link to={`/${getLanguageCodeFromSession()}/login`} className="forgot-password mx-auto mt-4 mx-md-0 mt-md-0">
                                                Login
                                            </Link>
                                        </div>
                                    </Form>
                                    :
                                    <></>
                                    }

                                {displayOTPForm === true ?
                                    <Form className="login-form d-flex flex-column" id="otp_new_password_form" onSubmit={validateForm} noValidate>
                                        {showErrorResponse &&
                                            <Alert color="danger">
                                                {ErrorResponse.length > 1 ? 
                                                <ul>
                                                    {ErrorResponse.map( (mess) =>
                                                    {
                                                        return (<li>{mess}</li>)
                                                    })}
                                                </ul>
                                                :
                                                <>{ErrorResponse[0]}</>
                                                }
                                            </Alert>}
                                        {showSuccessResponse &&
                                            <Alert color="success">
                                                {SuccessResponse}
                                            </Alert>}

                                        <div className="form-group">
                                            <label>Reset Session</label>
                                            <input type="text" className="form-control" placeholder="Enter OTP" id="Otp" name="Otp" readOnly
                                                required={true}
                                                value={Otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" className="form-control" placeholder="Enter Email" id="TargetEmail" name="TargetEmail" readOnly
                                                required={true}
                                                value={TargetEmail}
                                                onChange={(e) => setTargetEmail(e.target.value)}
                                            />
                                        </div>

                                        <FormGroup className='required position-relative'>
                                            <Label>New Password</Label>
                                            <span className="btn-show-password btn-link position-absolute" role="button" tabIndex="0" onClick={(e) => {e.target.blur(); setnewPassToggle(!newPass)}}>
                                                {newPass ?
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Show", "lbl_check_show")
                                                        :
                                                        "Show"
                                                    }
                                                </>
                                                :
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Hide", "lbl_check_hide")
                                                        :
                                                        "Hide"
                                                    }
                                                </>
                                                }
                                            </span>
                                            <Input type={newPass ? 'password' : 'text' }  placeholder="Enter new password" id="password" name="password"
                                                required={true}
                                                value={Password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                onFocus={(e) => removeFormError(e.target.id)}
                                                invalid={!!formErrors.password}
                                                >
                                                </Input>
                                            <FormFeedback>{formErrors.password}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required position-relative'>
                                            <Label>Confirm New Password</Label>
                                            <span className="btn-show-password btn-link position-absolute" role="button" tabIndex="0" onClick={(e) => {e.target.blur(); setconfirmPassToggle(!confirmPass)}}>
                                                {confirmPass ?
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Show", "lbl_check_show")
                                                        :
                                                        "Show"
                                                    }
                                                </>
                                                :
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Hide", "lbl_check_hide")
                                                        :
                                                        "Hide"
                                                    }
                                                </>
                                                }
                                            </span>
                                            <Input type={confirmPass ? 'password' : 'text' }  placeholder="Confirm password" id="ConfirmPassword" name="ConfirmPassword"
                                                required={true}
                                                value={ConfirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                onFocus={(e) => removeFormError(e.target.id)}
                                                invalid={!!formErrors.ConfirmPassword}
                                                >
                                                </Input>
                                        <FormFeedback>{formErrors.ConfirmPassword}</FormFeedback>
                                        </FormGroup>
                                        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mt-auto">
                                            <Button type='submit' color='primary' className='mt-auto w-100' id="lbl_login_loginbtn">
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Reset Password", "lbl_login_resetpassword")
                                                    :
                                                    "Reset Password"
                                                }
                                            </Button>
                                            <Link to={`/${getLanguageCodeFromSession()}/login`} className="forgot-password mx-auto mt-4 mx-md-0 mt-md-0">
                                                Login
                                            </Link>
                                        </div>
                                    </Form>
                                    :
                                    <></>
                                }
                                </CardBody>
                            </Card>
                        </div>

                        {SignupOption === true && !displayOTPForm ?
                        <div className='col-12 col-md-6 mt-5 mt-md-0'>
                            <NewCustomerCard />
                        </div>
                        :
                        <></>
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default ResetPassword;
