import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardSubtitle, CardBody } from 'reactstrap'
import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';

const NewCustomerCard = () => {
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const newCustomerBulletPoint = [
        {title: 'Track your Order Status 24/7', langkey: 'lbl_login_bulletponit1'},
        {title: 'Exclusive Promotions', langkey: 'lbl_login_bulletponit3'},
    ];

    return (
        <Card color="light" className="border-0 card--has-bg-color h-100">
            <CardHeader className='border-0 card-header--no-divisor'>
                <CardTitle tag="h2">
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, " New Customer", "lbl_login_newcustomer")
                        :
                        "New Customer"
                    }
                </CardTitle>
            </CardHeader>
            <CardBody className='d-flex flex-column'>
                <h4 className='mb-5'>
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Why Create an Account?", "lbl_login_whycreateaccount")
                        :
                        "Why Create an Account?"
                    }
                </h4>
                <ul className='pl-5'>
                {newCustomerBulletPoint.map((e, i) => (
                    <li key={i}>
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, e.title, e.langkey)
                            :
                            e.title
                        }
                    </li>
                ))}
                </ul>
                <Link to={`/${getLanguageCodeFromSession()}/signup`} className="btn btn-primary mt-auto" id="lbl_login_createacnt_2">
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Create an Account", "lbl_login_createacnt_2")
                        :
                        "Create an Account"
                    }
                </Link>
            </CardBody>
        </Card>
    );
}

export default NewCustomerCard;
