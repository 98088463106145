
const ExpiryInput = (props) => {
    const { delimiter = "/" } = props;
    const state = {
        maxLength: delimiter.length + 4,
        value: ""
    };

    const correctInput = (event) => {
        var target = event.target,
            value = target.value,
            key = event.key,
            typeKey,
            monthMin = "01",
            monthMax = "12",
            yearMin = new Date().getFullYear() - 2000,
            yearMax = new Date().getFullYear() - 2000 + 25;

        if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
            if (/(\d)/.test(key)) {
                typeKey = "number";
            } else {
                typeKey = "specSymbol";
            }

            if (value.length == 0 && key > 1) {
                // 2 -> 02/
                target.value = "0" + key + delimiter;
                props.updateProperty(event);
                event.preventDefault();
            }

            if (value.length == 1 && value[0] != "0" && key > 2) {
                // 16 -> 12/
                target.value = monthMax + delimiter;
                props.updateProperty(event);
                event.preventDefault();
                return;
            }

            if (value.length == 1 && typeKey != "specSymbol") {
                //12 backspace -> 1
                target.value = target.value + key + delimiter;
                props.updateProperty(event);
                event.preventDefault();
            }

            if (value.length == 2 && typeKey != "specSymbol") {
                // 2 -> 02/
                target.value = target.value + delimiter + key;
                props.updateProperty(event);
                event.preventDefault();
                return;
            }

            if (value == "0" && key == "0") {
                // 00 -> 01
                target.value = monthMin + delimiter;
                props.updateProperty(event);
                event.preventDefault();
                return;
            }

            if (target.value.length + 1 == state.maxLength) {
                // 12/11 -> 12/16
                var arr = target.value.split(delimiter);

                if (arr[0].length == 2 && arr[1] + key < yearMin) {
                    target.value = arr[0] + delimiter + yearMin;
                    props.updateProperty(event);
                    event.preventDefault();
                    return;
                }

                if (arr[0].length == 2 && arr[1] + key > yearMax) {
                    // 12/55 -> 12/41
                    target.value = arr[0] + delimiter + yearMax;
                    props.updateProperty(event);
                    event.preventDefault();
                    return;
                }
            }

        } else {
            event.preventDefault();
            return;
        }
    }

    return (
        <input
            id={props.id}
            onKeyDown={correctInput}
            name={props.name}
            onChange={props.onChange}
            onFocus={props.onFocus}
            className={props.className}
            maxLength={state.maxLength}
            placeholder={props.placeholder}
            type="text"
        />
    )
};

export default ExpiryInput;
