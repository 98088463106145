import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Button, Input } from 'reactstrap';


const SelectSearch = ({ id, label, placeholder, options = [], callback = null, defaultValue = -1, styleprop, employeeDropdown }) => {
    const [filter, setFilter] = useState('');
    const [selectedText, setSelectedText] = useState(defaultValue > -1 ? options[defaultValue].label : placeholder ?? '');
    const [showOptions, setShowOptions] = useState(false);

    const toggle = (target) => {
        if (target.tagName === 'INPUT')
            return;

        setShowOptions(!showOptions);
        if (!showOptions)
            window.setTimeout(function () {
                document.getElementById(id).focus();
            }, 0);
    }

    const isVisible = (text) => {
        return !filter || text?.toLowerCase().indexOf(filter?.toLowerCase()) > -1;
    };

    const onSelectClick = (index) => {
        setFilter('');
        setSelectedText(index > -1 ? options[index]?.label : placeholder);
        if (callback) callback(index);
    };

    return (
        <div>
            <FormGroup className='select-search-wrap mb-0'>
                <Label for='pbe-selector' className='size-attribute-title label mb-2'>
                    {label}
                </Label>
                {
                    styleprop 
                    ?
                    <div className='innerselectSearchwrapper' onClick={(e) => toggle(e.target)}>
                        <Label className={`custom-select`} >{selectedText}</Label>
                        <div className={`custom-input-wrapper`}><Input type='text' value={filter} id={id} className={`${!showOptions ? 'hidden' : ''}`} onChange={(e) => setFilter(e.target.value)}></Input></div>
                        <div className={`options-list ${!showOptions ? 'hidden' : ''}`}>
                            <ul>
                                
                                {(!employeeDropdown && placeholder) && 
                                    <li value={-1} onClick={() => onSelectClick(-1)}>{placeholder}</li>
                                }
                                {options?.map((option, index) => {
                                    return (
                                        <li value={index}
                                            className={`${isVisible(option.label) ? '' : 'hidden'}`}
                                            onClick={() => onSelectClick(index)} 
                                            title={option.label}>
                                                {option.label}
                                        </li>
                                    )
                                })}
                            </ul> 
                        </div>
                    </div>
                    : 
                    <>
                        <Label className={`custom-select`}  onClick={(e) => toggle(e.target)}>{selectedText}</Label>
                        <Input type='text' value={filter} id={id} className={`${!showOptions ? 'hidden' : ''}`} onChange={(e) => setFilter(e.target.value)}></Input>
                        <div className={`options-list ${!showOptions ? 'hidden' : ''}`} onClick={(e) => toggle(e.target)}>
                            <ul>
                                {placeholder && 
                                    <li value={-1} onClick={() => onSelectClick(-1)}>{placeholder}</li>
                                }
                                {options?.map((option, index) => {
                                    return (
                                        <li value={index}
                                            className={`${isVisible(option.label) ? '' : 'hidden'}`}
                                            onClick={() => onSelectClick(index)} 
                                            title={option.label}>
                                                {option.label}
                                        </li>
                                    )
                                })}
                            </ul> 
                        </div>
                    </>
                }
            </FormGroup>
        </div>
    );

}


export default SelectSearch;
