import { createSlice } from '@reduxjs/toolkit';
import { checkoutApi } from '../../services/checkout';

type CreditCardSafe = {
    last4: string
    cardType: string
    expMonth: string
    expYear: string
}

type OrderConfirmation = {
    orderNumber: string
    sfccOrderNo: string
    orderDate: string
    paymentInstruments: any[]
    billingAddress?: any,
    shippingAddress?: any,
    shippingMethod?: any,
    cart: any[],
    customerPurchaseOrder: string
}

const initialState: OrderConfirmation = {
    orderNumber: '',
    sfccOrderNo: '',
    orderDate: '',
    billingAddress: null,
    shippingAddress: null,
    paymentInstruments: [],
    shippingMethod: {},
    cart: [],
    customerPurchaseOrder: '',
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        reset: () => {
            return initialState;
        },
        setOrderConfirmation: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(checkoutApi.endpoints.placeOrder.matchFulfilled, (state, action) => {
                state.orderDate = (new Date(action.payload.creation_date)).toLocaleDateString()
                state.paymentInstruments = action.payload.payment_instruments
            })
    }
})


export const { reset, setOrderConfirmation } = orderSlice.actions;
export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
