import { useState, useContext } from "react";
import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";
import { formatCurrency } from '../../../helpers/ConversionHelper.js';
import { Link, useNavigate } from "react-router-dom";
import _ from 'lodash';
import { ToggleContext } from "../../../ToggleProvider";


export const ProductBit = ({ product, productDetailPageForceUpload }) => {
    const [hoveredProduct, setHoveredProduct] = useState(false);
    const [activeImageVariation, setActiveImageVariation] = useState(product?.colorSwatches?.[0] || product.defaultImage);
    const navigate = useNavigate();
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    const reloadProductDetail = (_productId, _categoryName, _productName) => {
        let productDetailUrlFromForceReload = `/${getLanguageCodeFromSession()}/product-detail/${_productId}/${_.kebabCase(_categoryName) ?? "shop"}/${_.kebabCase(_productName)}`

        if (productDetailPageForceUpload !== undefined && productDetailPageForceUpload !== null && productDetailPageForceUpload === true && _productId !== undefined) {
            navigate(productDetailUrlFromForceReload, { replace: true });
            window.location.reload();
        }
    }
    return (
        <div className="col product-tile-column" key={product.ProductId}>
            <div className="product-tile">
                <div
                    className="product-image"
                    onMouseEnter={() => setHoveredProduct(true)}
                    onMouseLeave={() => setHoveredProduct(false)}
                >
                    {(() => {
                        let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${product.ProductId}/${product.CategoryName ?? "shop"}/${_.kebabCase(product.ProductName)}`;
                        return (
                            <Link to={urlViewDetailImage} onClick={() => reloadProductDetail(product.ProductId, product.CategoryName, product.ProductName)}>
                                <img id={"PRODIMG_" + product.ProductId} src={hoveredProduct ? activeImageVariation.hoverImageUrl : activeImageVariation.imageUrl} alt={product.ProductId} className="tile-image" />
                            </Link>
                        );
                    })()}
                </div>

                <div className="product-tile-body">
                    <Link
                        to={`/${getLanguageCodeFromSession()}/product-detail/${product.ProductId}/${product.CategoryName ?? "shop"}/${_.kebabCase(product.ProductName)}`}
                        className="pdp-link"
                    >
                        {product.IsOnlyAtUa &&
                            <b><span className="only-ua-tag">Only <span className="at-symbol">@</span>UA<span className="register-mark">®</span> </span></b>}
                        {product.ProductName}
                    </Link>
                    {!isToggled?
                    <>
                    <div className="product-price sale">
                        {product.Price==999999?"Sold Out" : "From "+ formatCurrency(product.MinPrice || product.Price)}
                    </div>
                    </>:<></>
                    }

                    <div className="product-style">
                        Style #{product.ProductId}
                    </div>

                    <div className="tile-body-footer mt-2">
                        <span className="tile-length">{product.LengthAvailableIn}</span>
                    </div>

                    <div className="color-swatches">
                        {product?.colorSwatches?.map((color, idx) => (
                            <Link
                                to={`/${getLanguageCodeFromSession()}/product-detail/${product.ProductId}-${color.code}/${product.CategoryName ?? "shop"}/${_.kebabCase(product.ProductName)}?color=${color.code}`}
                                key={idx} title={color.name}
                                className={`color-attribute swatch-circle ${color.hex?.toUpperCase() === '#FFFFFF' ? 'swatch-white' : ''}`}
                                style={ color.hex?.split("_").length>1?{background: 'linear-gradient(to right,'+ color.hex?.split("_")[0]+' 70%, '+color.hex?.split("_")[1]+' 0)'}:{ backgroundColor: color.hex }}
                                onMouseOver={(e) => setActiveImageVariation(color)}
                            />
                        ))} 
                    </div>
                </div>
            </div>
        </div>
    );
}
